import * as vis from './visibility';

const showImagePositionFields = () => {
  const classes = document.querySelector('body').classList;
  if(classes.contains('exercises') && (classes.contains('edit') || classes.contains('new'))){
    const imageField = document.querySelector('#media-fields');
    if(imageField) vis.showById('image-position-field');
  }
}

$(document).on('cocoon:after-insert', function(e) {
  showImagePositionFields()
})
