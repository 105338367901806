import _ from 'lodash';

export const normalize = (string, requireSpecialCharacters=true) => {
  if (requireSpecialCharacters) return string.toLowerCase().trim();

  return _.deburr(string.toLowerCase().trim())
}

export const removeApostrophes = (string) => {
  return string.replaceAll(/'/g, '-');
}
