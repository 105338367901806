import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-character-counter"
export default class extends Controller {
  static targets = [ "input", "count" ]
  static values = { limit: Number }
  connect() {
    console.log(this.limitValue)
    this.update()
  }

  update() {
    const count = this.inputTarget.value.length
    this.countTarget.textContent = `${count}/${this.limitValue}`

    if (count > this.limitValue) {
      this.countTarget.classList.add('tw-text-red-500')
      this.inputTarget.classList.add('tw-border-red-500')
    } else {
      this.countTarget.classList.remove('tw-text-red-500')
      this.inputTarget.classList.remove('tw-border-red-500')
    }
  }
}
