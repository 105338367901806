import React, { Fragment } from "react"
import PropTypes from "prop-types"
import clearSelection from '../app/clearSelection';
import * as vis from '../app/visibility';
import _ from 'lodash';

class TextBlanksField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      text: '',
      hiddenIndices: [],
    };
    this.setSentence = this.setSentence.bind(this);
    this.addBlank = this.addBlank.bind(this);
    this.handleAddBlank = this.handleAddBlank.bind(this);
    this.reset = this.reset.bind(this);
    this.editText = this.editText.bind(this);
    this.handleMultipleChoice = this.handleMultipleChoice.bind(this);
    this.container = React.createRef();
  }

  componentDidMount() {
    const newState = this.state;
    if(this.props.text){
      newState.text = this.props.text;
      this.toggleTextFields();
    }
    if(this.props.hidden_indices){
      newState.hiddenIndices = this.sortIndices(
        JSON.parse(this.props.hidden_indices)
      );
    }
    newState.multipleChoice = this.props.multiple_choice;
    newState.loading = false;
    this.setState(newState);
  }

  // sort into chronological order for easier processing when rendering
  sortIndices(indices){
    return _.sortBy(indices, [function(i) { return i[0]; }]);
  }

  handleChange(e){
    this.setState({ text: e.target.value })
  }

  setSentence(e){
    e.preventDefault();
    const textInput = this.container.current.parentElement.previousElementSibling;
    if(textInput && textInput.value){
      textInput.classList.add('hidden');
      textInput.previousElementSibling.classList.add('hidden');
      this.setState(
        { text: textInput.value },
        () => {
          this.container.current.scrollIntoView({ behavior: 'smooth' });
        }
      )
    }
  }

  toggleTextFields(){
    const textInput = this.container.current.parentElement.previousElementSibling;
    const label = textInput.previousElementSibling;
    vis.toggle(textInput);
    vis.toggle(label);
  }

  handleAddBlank(e){
    e.preventDefault();
    if(this.state.multipleChoice && this.state.hiddenIndices.length > 0){
      alert("You can only blank 1 word for a multiple choice question.")
    } else{
      this.addBlank();
    }
  }

  addBlank(){
    const { anchorOffset, focusOffset, anchorNode } = window.getSelection();

    // only execute if selection is in the right place
    if(anchorNode.parentElement.classList.contains('blanks-selector')){
      let mouseStart = anchorOffset;
      let mouseEnd = focusOffset;

      // swap based upon which way you use the mouse
      if(mouseStart > mouseEnd){
        mouseStart = focusOffset;
        mouseEnd = anchorOffset;
      }

      const start = mouseStart;
      const length = mouseEnd - start;

      if(!this.indicesOverlap(start, length)){
        const newState = this.state;
        newState.hiddenIndices.push([start, length]);
        // sort into chronological order
        newState.hiddenIndices = this.sortIndices(newState.hiddenIndices)
        this.setState(newState, this.clearSelectionAndUpdateHiddenIndiceField);
      } else {
        alert(
          "Invalid selection: You can't blank out the same words more than once."
        );
      }
    }
  }

  // TODO - REFACTOR to put the used indices in state so we don' need to calc
  // them every time.
  indicesOverlap(start, length){
    let overlap = false

    // store all the indices we have used
    const used_indices = [];
    this.state.hiddenIndices.forEach((hiddenPair) => {
      for(let i = hiddenPair[0]; i < hiddenPair[0] + hiddenPair[1]; i++){
        used_indices.push(i);
      }
    })

    const indices = []
    // indices in current word
    for(let i = start; i < start + length; i++){
      indices.push(i);
    }

    for(let i = 0; i < indices.length; i++){
      if(used_indices.includes(indices[i])){
        overlap = true;
        break;
      }
    }

    return overlap;
  }

  clearSelectionAndUpdateHiddenIndiceField(){
    clearSelection();
    const indiceField = this.container.current.parentElement.nextElementSibling;
    if(indiceField.name.includes('[hidden_indices]')){
      indiceField.value = `${JSON.stringify(this.sortIndices(this.state.hiddenIndices))}`;
    }
  }

  renderBlankedText(){
    return <p className='blanked-display h1'>
      {this.parseText(this.state.text)}
    </p>
  }

  // Split the text into a series of <p> and <input> based on hidden_indices
  parseText(text){
    const elements = [];
    let start = 0;

    this.state.hiddenIndices.forEach((indices, i) => {
      // create a <p> for first section if any
      const first = text.slice(start, indices[0]);
      if(first.length > 0) elements.push(<span key={'p-' + i}>{first}</span>)

      // update start for next iteration
      start = indices[0] + indices[1];

      const blanked = text.slice(indices[0], indices[0] + indices[1])
      elements.push(<span className='blanked'>{blanked}</span>)
    })

    // add last bit of text if there is any
    const lastSection = text.slice(start, text.length);
    if(lastSection.length > 0) elements.push(<span key={'p-' + elements.length}>{lastSection}</span>)

    return elements;
  }

  reset(e, callback=undefined){
    e.preventDefault();
    this.setState(
      { hiddenIndices: [] },
      () => { if(callback) callback() }
    );
  }

  editText(e){
    e.preventDefault();
    if(confirm('This will clear all the blanks you have selected!')){
      this.reset(
        e,
        () => {
          this.setState({ text: '' }, this.toggleTextFields)
        }
      );
    }
  }

  handleMultipleChoice(e){
    this.setState(
      { multipleChoice: e.target.checked },
      this.updateMultipleChoiceField
    )
  }

  updateMultipleChoiceField(){
    const field = this.container.current.parentElement.nextElementSibling.nextElementSibling;
    if(field.name.includes('[multiple_choice]')){
      field.value = this.state.multipleChoice ? '1' : '0'
    }
    const options = field.nextElementSibling;
    this.state.multipleChoice ? vis.show(options) : vis.hide(options);
  }

  render () {
    const { text, hidden_indices, loading, multipleChoice } = this.state;

    return (
      <div
        ref={this.container}
        className='mpt'
      >

        {loading && <h2>Loading...</h2>}

        {!loading &&
          <Fragment>
            {!text &&
              <button
                onClick={this.setSentence} className="button">Add Text
              </button>
            }

            {text &&
              <Fragment>

                <div
                  className="row align-middle lmb"
                  id="text-blanks-checkboxes"
                >
                  <label
                    htmlFor="placeholder"
                  >
                    Make this a multiple choice question?
                  </label>

                  <div className="mml checkbox-container relative mmt-small-only">
                    <input
                      name="placeholder"
                      id="placeholder"
                      type="checkbox"
                      checked={multipleChoice}
                      value="1"
                      onChange={this.handleMultipleChoice}
                      className='visually-hidden absolute'
                    />
                    <label htmlFor="placeholder" className="visually-hidden absolute top left"></label>
                    <div className="checkmark"></div>
                  </div>
                </div>


                <label>
                  Now highlight the word(s) you want to hide and click Add Blank. You will see the
                  <span className="italic"> Blanked Result </span>
                  below show your changes. Repeat for each bit you wish to blank out.
                </label>

                <p
                  className="blanks-selector"
                >
                  {this.state.text}
                </p>

                <nav className="row">
                  <button
                    className="button lmb"
                    style={{marginRight: '0.5rem'}}
                    onClick={this.handleAddBlank}
                  >
                    Add Blank
                  </button>

                  <button
                    className="button lmb"
                    style={{marginRight: '0.5rem'}}
                    onClick={this.reset}
                  >
                    Clear All Blanks
                  </button>

                  <button
                    className="button lmb"
                    style={{marginRight: '0.5rem'}}
                    onClick={this.editText}
                  >
                    Edit Text
                  </button>
                </nav>

                <h4>Blanked Result:</h4>

                {this.renderBlankedText()}


              </Fragment>
            }
          </Fragment>
        }


      </div>
    );
  }
}

export default TextBlanksField
