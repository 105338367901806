import React from "react";
import PropTypes from "prop-types";
import OptionButton from './OptionButton';
import SubmitButton from './SubmitButton';
import NextButton from './NextButton';
import SpecialCharactersModal from "./SpecialCharactersModal";
import NumberLabel from './NumberLabel';
import Image from './Image';
import Errors from './Errors';
import Feedback from './Feedback';
import RevealAnswer from './RevealAnswer';
import AudioPlayer from './AudioPlayer';
import PreviousQuestionButton from './PreviousQuestionButton';
import * as correctStyler from '../app/correctStyler';
import * as solutions from '../app/solutions';
import { normalize } from '../app/normalize';
import _ from 'lodash';
import redirectToExerciseOrLesson from './redirectToExerciseOrLesson'
import mergeSolutions from './mergeSolutions';

class FillInTheBlank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      solutions: this.props.solutions,
      current: 0,
      answers: [],
      feedback: '',
      errors: null,
      maxWidth: null,
      attempts: 0,
      answeredCorrectly: false
    }
    this.handleNext = this.handleNext.bind(this);
    this.getCurrent = this.getCurrent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkAnswers = this.checkAnswers.bind(this);
    this.nextButtonText = this.nextButtonText.bind(this);
    this.nextDisabled = this.nextDisabled.bind(this);
    this.handleOptionSelect = this.handleOptionSelect.bind(this);
    this.loadPrevious = this.loadPrevious.bind(this);
  }

  componentDidMount() {
    this.loadSolutions()
  }

  loadSolutions() {
    const solution = this.state.solutions[this.getCurrent().id];
    if (solution) {
      this.setState(
        { answers: JSON.parse(solution.body) },
        this.checkAnswers
      )
    }
  }

  getCurrent() {
    return this.props.texts[this.state.current]
  }

  renderText() {
    return <div className="outer-text-container" key={'text-' + this.state.current}>
      <div className="fill-in-blanks-text-container">
        {this.parseText(this.getCurrent())}
      </div>
    </div>
  }

  // Split the text into a series of <p> and <input> based on hidden_indices
  parseText(text) {
    const elements = [];
    let start = 0;

    text.hidden_indices.forEach((indices, i) => {
      // create a <p> for first section if any
      const first = text.text.slice(start, indices[0]);
      if (first.length > 0) elements.push(<p key={'p-' + i}>{first}</p>)
      // update start for next iteration
      start = indices[0] + indices[1];

      const inputWidth = (indices[1] * 32) + 'px';
      elements.push(this.renderBlank(i, text, inputWidth))
    })

    // add last bit of text if there is any
    const lastSection = text.text.slice(start, text.text.length);
    if (lastSection.length > 0) elements.push(<p key={'p-' + elements.length}>{lastSection}</p>)

    return elements;
  }

  renderBlank(index, text, width) {
    if (text.multiple_choice) {
      return <p
        key={'input-' + index}
        name={index}
        id={'text-' + text.id}
        style={{ width: width, maxWidth: '100%', lineHeight: '4rem' }}
        className='lf-placeholder'
      >...</p>
    } else {
      return <input
        key={'input-' + index}
        name={index}
        type="text"
        id={'text-' + text.id}
        style={{ width: width, maxWidth: '100%', padding: '0' }}
        onChange={this.handleChange}
        className='lf-input'
        placeholder='...'
        value={this.state.answers[index] ? this.state.answers[index] : ''}
      />
    }
  }

  renderOptions() {
    if (this.getCurrent().multiple_choice) {
      const options = this.getCurrent().options.map((o, i) => (
        <OptionButton handleClick={this.handleOptionSelect} option={o} key={'option-' + i} id={'option-' + _.kebabCase(o)} name={i} />
      ))

      return <section className="grid-container lmb">
        <div className="grid-x grid-margin-x grid-margin-y align-center">{options}</div>
      </section>
    }
  }

  handleOptionSelect(value) {
    correctStyler.clearCorrectStyles();
    const newState = this.state;
    // Multiple choice questions only ever have 1 blank to answer, so we push()
    newState.answers = [value];
    newState.feedback = '';
    this.setState(newState, this.checkAnswers);
  }

  handleChange(e) {
    correctStyler.clearStyleFor(`input[name="${e.target.name}"]`)
    const newState = this.state;
    newState.answers[e.target.name] = e.target.value;
    newState.feedback = '';
    this.setState(newState);
  }

  checkAnswers() {
    this.updateUI();
    const newState = this.state;
    newState.feedback = this.feedback();
    newState.attempts = this.state.attempts + 1;
    if (this.allCorrect() && this.allComplete()) {
      newState.answeredCorrectly = true;
    }
    this.setState(newState);
    this.createAnswer();
  }

  updateUI() {
    correctStyler.clearCorrectStyles();
    this.state.answers.forEach((a, i) => {
      const selector = this.getCurrent().multiple_choice ? `#option-${_.kebabCase(a)}` : `input[name="${i}"]`
      const input = document.querySelector(selector);
      if (input) correctStyler.style(selector, this.isCorrect(a, i));
    })
  }

  isCorrect(answer, index) {
    const requireSpecialCharacters = this.getCurrent().require_special_characters

    if (requireSpecialCharacters) return normalize(this.getHiddenChars(index)) === normalize(answer);

    // if requireSpecialCharacters is false the correct answer should have two values: one 'truly' correct and one stripped of special characters
    // therefore we return array of arrays
    const correctAnswers = [
      normalize(this.getHiddenChars(index), requireSpecialCharacters),
      normalize(this.getHiddenChars(index), !requireSpecialCharacters)
    ]

    return correctAnswers.includes(normalize(answer))
  }

  getHiddenChars(index) {
    const { text, hidden_indices } = this.getCurrent();
    const indices = hidden_indices[index]
    return text.slice(
      indices[0],
      indices[0] + indices[1]
    );
  }

  createAnswer() {
    solutions.create(
      {
        soluble_id: this.getCurrent().id,
        soluble_type: 'Text',
        body: this.state.answers,
        correct: this.allCorrect() && this.allComplete(),
        user_id: this.props.user
      },
      (solution) => mergeSolutions(solution, this.state, this),
      (e) => {
        this.setState(
          { errors: e },
          () => { console.log(e) }
        )
      }
    )
  }

  allCorrect() {
    // If they are, check if all are correct
    let allCorrect = true;
    for (let i = 0; i < this.state.answers.length; i++) {
      const answer = this.state.answers[i]
      if (!this.isCorrect(answer, i)) {
        allCorrect = false;
        break;
      }
    }
    return allCorrect;
  }

  allComplete() {
    return this.state.answers.length === this.getCurrent().hidden_indices.length;
  }

  feedback() {
    if (this.allCorrect() && this.allComplete()) {
      return this.correctMessage();
    } else if (!this.allComplete()) {
      return "You've missed some answers."
    } else {
      return this.inCorrectMessage();
    }
  }

  inCorrectMessage() {
    let msg = 'Oh no. Not quite right.';
    if (this.getCurrent().require_special_characters) {
      msg = msg.concat(" Check your spelling or make sure you use the right accents or special characters.")
    }
    return msg;
  }

  correctMessage() {
    return this.isLast() ? "Nice one! You're all done." : 'Excellent!'
  }

  isLast() {
    return this.state.current === this.props.texts.length - 1
  }

  nextButtonText() {
    const finishText = this.props.nextId ? 'Next Exercise' : 'Finish'
    return this.isLast() ? finishText : 'Next Question';
  }

  handleNext() {
    if (!this.isLast()) {
      this.next();
    } else {
      this.checkAnswers();
      redirectToExerciseOrLesson(this.props)
    }
  }


  next(forward = true) {
    correctStyler.clearCorrectStyles();
    const change = forward ? 1 : -1;
    this.setState({
      current: this.state.current + change,
      answers: [],
      errors: null,
      feedback: '',
      attempts: 0,
      answeredCorrectly: false
    }, this.loadSolutions)
  }

  nextDisabled() {
    // return this.state.answers && this.state.answers.length === 0 || (this.state.answers.length === 1 && this.state.answers[0] === "")

    return !this.state.answeredCorrectly;
  }

  renderSubmitButton() {
    if (!this.getCurrent().multiple_choice) {
      return <SubmitButton
        submitText='Check Answer'
        disabled={this.submitDisabled()}
        onClick={this.checkAnswers}
      />
    }
  }

  answered() {
    return this.state.answers.length > 0;
  }

  nextExerciseOrLessonExists() {
    let exists = false;
    if (this.props.nextId || this.props.lessonId) exists = true;
    return exists;
  }

  renderNextButton() {
    const couldShow = !this.isLast() || this.nextExerciseOrLessonExists();
    if (this.answered() && couldShow) {
      return <NextButton
        disabled={this.nextDisabled()}
        onClick={this.handleNext}
        text={this.nextButtonText()}
      />
    }
  }

  submitDisabled() {
    return this.state.answers.length === 0;
  }

  loadPrevious() {
    if (this.state.current > 0) this.next(false);
  }

  render() {
    const {
      errors,
      feedback,
      current,
      attempts,
      answeredCorrectly
    } = this.state;

    return (
      <div className='fill-in-the-blank text-center'>
        <NumberLabel
          current={current + 1}
          total={this.props.texts.length}
        />

        <Image imageable={this.getCurrent()} />

        <AudioPlayer
          audio={this.getCurrent().audio}
          audibleId={this.getCurrent().id}
        />

        {this.renderText()}
        {this.renderOptions()}

        <Feedback feedback={feedback} />

        {(this.nextDisabled() && !this.submitDisabled()) && <p className="alert-bg mp">NOTE: You need to check the answer before going to the next question.</p> }

        <div className="grid-x align-center">
            {this.renderSubmitButton()}
          {this.renderNextButton()}
        </div>

        <RevealAnswer
          buttonVisible={attempts > 0 && !answeredCorrectly}
          answers={[this.getCurrent().text]}
        />

        <SpecialCharactersModal />

        <PreviousQuestionButton
          visible={current > 0}
          handleClick={this.loadPrevious}
        />

        <Errors errors={errors} />
      </div>
    );
  }
}

FillInTheBlank.propTypes = {
  fillInTheBlank: PropTypes.object.isRequired,
  lessonId: PropTypes.number,
  user: PropTypes.number.isRequired
};

export default FillInTheBlank
