import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="speech-to-text"
export default class extends Controller {
  static targets = [ "input" , "speechButton" ]
  static values = { language: String }

  connect() {
    try {
      this.recognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      this.recognition = new this.recognition();
      this.recognition.interimResults = true;
      this.recognition.lang = this.languageToCode();
      this.recognition.continuous = true;
      this.recognition.onresult = (event) => {
        const transcript = Array.from(event.results).map(result => result[0]).map(result => result.transcript).join('');
        this.inputTarget.value = transcript;
      }
    } catch (error) {
      // change the button to div with text
      this.speechButtonTarget.outerHTML = `
      <p id="speechButton" class="tw-text-sm tw-text-gray-900 tw-mt-4 tw-mb-4 tw-p-3 tw-bg-red-500/10 tw-rounded tw-border tw-border-gray-300" data-speech-to-text-target="speechButton">
        Sorry, it seems that your browser does not support speech recognition. Please try again with a different browser.
      </p>`;
    }
  }

  startVoiceInput(e)  {
    this.recognition.start();

    // make the button for stop listening

    e.target.dataset.action = "click->speech-to-text#stopVoiceInput";
    e.target.innerHTML = `<i class="fa fa-microphone-slash tw-pointer-events-none"></i>`;
  }

  stopVoiceInput(e) {
    this.recognition.stop();

    // make the button for start listening
    e.target.dataset.action = "click->speech-to-text#startVoiceInput";
    e.target.innerHTML = `<i class="fa fa-microphone tw-pointer-events-none"></i>`;
  }

  languageToCode() {
    switch (this.languageValue) {
      case "English":
        return "en-US";
      case "French":
        return "fr-FR";
      case "Spanish":
        return "es-ES";
      case "German":
        return "de-DE";
      case "Italian":
        return "it-IT";
      case "Japanese":
        return "ja-JP";
      case "Korean":
        return "ko-KR";
      case "Chinese":
        return "zh-CN";
      default:
        return "en-US";
    }
  }

  disconnect() {
    this.recognition.stop();
  }
}
