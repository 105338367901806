import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="submit-on-change"
export default class extends Controller {
  static targets = [ "form" ]
  connect() {
  }

  submit(event) {
    this.formTarget.requestSubmit();
  }
  
}
