import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-switch"
export default class extends Controller {
  static targets = ["word"];

  connect() {
    this.index = 0; // Initialize an index to keep track of the current word
    this.switchWords(); // Call switchWords on connect to start the cycle
    this.interval = setInterval(() => {
      this.switchWords();
    }, 2500); // Repeat every 2 seconds
  }

  disconnect() {
    clearInterval(this.interval); // Clear the interval when the controller is disconnected
  }

  switchWords() {
    // Get the current 'visible' word
    const currentWord = this.wordTargets.find((element) => 
      element.classList.contains('is-visible')
    );

    // Get the next word or loop back to the start
    const nextWord = this.getNextWord(currentWord);

    // Switch the classes
    currentWord.classList.remove('is-visible');
    currentWord.classList.add('is-hidden');
    nextWord.classList.remove('is-hidden');
    nextWord.classList.add('is-visible');
  }

  getNextWord(currentWord) {
    const nextIndex = (this.index + 1) % this.wordTargets.length;
    this.index = nextIndex; // Update the index
    return this.wordTargets[nextIndex]; // Return the next word
  }
}
