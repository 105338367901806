import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SubmitButton from './SubmitButton';
import NextButton from './NextButton';
import Errors from './Errors';
import * as correctStyler from '../app/correctStyler';
import * as solutions from '../app/solutions';
import { normalize } from '../app/normalize';
import notNil from '../app/notNil';
import SpecialCharactersModal from "./SpecialCharactersModal";

class BlanksGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: null,
      ready: false,
      errors: null,
      feedback: '',
      attempts: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setDisabled = this.setDisabled.bind(this);
    this.revealAnswers = this.revealAnswers.bind(this);
  }

  // create empty answers to match length of blanksGrid prop
  componentDidMount() {
    this.setBlanks();
    this.loadSolution();
  }

  setBlanks() {
    const blanks = this.props.blanksGrid.answers.map((s) => (''))
    this.setState({ answers: blanks, ready: true })
  }

  loadSolution() {
    if (this.props.solutions) {
      this.setState(
        { answers: JSON.parse(this.props.solutions.body) },
        this.handleSubmit
      )
    }
  }

  renderInputs() {
    return this.props.blanksGrid.answers.map((a, i) => (
      <div className="cell small-6 medium-4 xlarge-3" key={'blank-' + i}>
        <input
          id={'blanks-grid-input-' + i}
          type="text"
          name={i}
          value={this.state.answers[i]}
          onChange={this.handleChange}
          placeholder='Type answer here'
        />
      </div>

    ));
  }

  // update the inputs using input value
  // clear any feedback given
  handleChange(e) {
    correctStyler.clearCorrectStyles()
    const newState = this.state;
    newState.answers[e.target.name] = e.target.value;
    newState.feedback = '';
    this.setState(newState);
  }

  // Disable submit button until at least 1 answer has been given
  setDisabled() {
    if (this.state.answers) {
      let disabled = true;
      for (let i = 0; i < this.state.answers.length; i++) {
        if (notNil(this.state.answers[i])) {
          disabled = false;
          break;
        }
      }
      return disabled;
    } else {
      return true
    }
  }

  // clear all correct styling
  // check if they are all correct and style accordingly
  // create an Answer
  // update UI to show feedback and Finish buton
  handleSubmit(saveAnswer = true) {
    correctStyler.clearCorrectStyles();
    let allCorrect = true;
    this.state.answers.forEach((a, i) => {
      correctStyler.style('#blanks-grid-input-' + i, this.isCorrect(i));
      if (!this.isCorrect(i)) allCorrect = false;
    })
    if (saveAnswer) this.createAnswer(allCorrect);
    this.setState({
      showFinish: true,
      feedback: allCorrect ? 'Well done!' : this.incorrectMessage(),
      attempts: this.state.attempts + 1
    })
  }

  incorrectMessage() {
    let msg = 'Oh no! Not quite there.';
    if (this.props.blanksGrid.require_special_characters) {
      msg = msg.concat(" Check your spelling or make sure you use the right accents or special characters.")
    }
    return msg;
  }

  // check given answer matches answer provided in blanksGrid prop
  isCorrect(index) {
    const requireSpecialCharacters = this.props.blanksGrid.require_special_characters;

    const answer = normalize(this.state.answers[index]);

    // if requireSpecialCharacters is false the correct answer should have two values: one 'truly' correct and one stripped of special characters
    if (requireSpecialCharacters) {

      const correctAnswer = normalize(this.props.blanksGrid.answers[index], requireSpecialCharacters);

      return answer === correctAnswer
    } else {

      const correctAnswers = [normalize(this.props.blanksGrid.answers[index], requireSpecialCharacters), normalize(this.props.blanksGrid.answers[index], !requireSpecialCharacters)];

      return correctAnswers.includes(answer)
    }
  }

  // create answer model and send post/patch request
  createAnswer(correct) {
    solutions.create(
      {
        soluble_id: this.props.blanksGrid.id,
        soluble_type: 'BlanksGrid',
        body: this.state.answers,
        correct: correct,
        user_id: this.props.user
      },
      () => {
        console.log('Solutiuon Created'); // NOTE - show some feedback here
      },
      (e) => {
        this.setState(
          { errors: e },
          () => { console.log(e) }
        )
      }
    )
  }

  handleNext() {
    let dest = 'lessons';
    let id = this.props.lessonId;
    if (this.props.nextId) {
      dest = 'exercises';
      id = this.props.nextId;
    }
    let url = '/' + dest + '/' + id;
    if (!this.props.nextId) url += '/finish'
    window.location = url;
  }

  revealAnswers() {
    const userAnswers = this.state.answers;
    this.setState({ answers: this.props.blanksGrid.answers, reveal: true })
    correctStyler.clearCorrectStyles();
    this.state.answers.forEach((a, i) => {
      correctStyler.style('#blanks-grid-input-' + i, true);
    })
    setTimeout(
      () => this.resetUserAnswers(userAnswers),
      2000
    )
  }

  resetUserAnswers(answers) {
    this.setState(
      { answers: answers, reveal: false },
      () => this.handleSubmit(false)
    )
  }

  render() {
    const { ready, feedback, errors } = this.state;
    const { nextId } = this.props;

    let { showFinish } = this.state;
    if (!this.props.nextId && !this.props.lessonId) showFinish = false;

    if (!ready) return <p className="lead">Loading...</p>

    return (
      <div className='blanks-grid grid-container fluid text-center'>
        <div className="grid-x grid-margin-x">
          {this.renderInputs()}
        </div>

        <div
          className={`cell medium-order-2 large-order-3 lf-feedback-flash toggle-trans ${feedback ? '' : 'trans'}`}
        >
          <p className='h4'>{feedback}</p>
        </div>

        <div className="grid-x align-center">
          <SubmitButton
            disabled={this.setDisabled()}
            onClick={this.handleSubmit}
          />

          {showFinish &&
            <NextButton
              onClick={this.handleNext}
              text={nextId ? 'Next Exercise' : 'Finish'}
            />
          }
        </div>

        {this.state.attempts > 0 &&
          <button
            className={`reveal-answer-button button small ${this.state.reveal ? 'revealing ignore-click' : ''}`}
            onClick={this.revealAnswers}
          >
            <i class="fa fa-eye inline-block" aria-hidden="true"></i>
            {this.state.reveal ? 'Revealing...' : 'Reveal Answer'}
          </button>
        }

        <Errors errors={errors} />
        <SpecialCharactersModal />
      </div>
    );
  }
}

BlanksGrid.propTypes = {
  blanksGrid: PropTypes.object.isRequired,
  lessonId: PropTypes.number,
  nextId: PropTypes.number,
  user: PropTypes.number
};
export default BlanksGrid
