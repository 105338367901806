import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="conversation-opener"
export default class extends Controller {
  static targets = ["content", "wrapper"]
  static values = { language: String, personaName: String, stage: String}
  static openers = {
    "french": ["Bonjour !", "Salut, comment ça va ?", "Hi !", "Hello, how are you ?"],
    "spanish": ["¡Hola !", "¡Hola, cómo estás ?", "Hi !", "Hello, how are you ?"],
    "italian": ["Ciao !", "Ciao, come stai ?", "Hi !", "Hello, how are you ?"],
    "german": ["Hallo !", "Hallo, wie geht's ?", "Hi !", "Hello, how are you ?"],
    "portuguese": ["Olá !", "Olá, como estás ?", "Hi !", "Hello, how are you ?"],
    "japanese": ["こんにちは！", "こんにちは、元気ですか？", "Hi !", "Hello, how are you ?"],
  }

  static questions = {
    "french": ["Dis-moi quelque chose d'intéressant sur la France", "Comment dire \"Merci\" en français ?", "Comment dire \"Bonjour\" en français ?", "Comment dire \"Au revoir\" en français ?", "Tell me something interesting about France", "How do I say \"Thank you\" in French ?", "How do I say \"Hello\" in French ?", "How do I say \"Goodbye\" in French ?"],
    "spanish": ["Dime algo interesante sobre España", "¿Cómo digo \"Gracias\" en español ?", "¿Cómo digo \"Hola\" en español ?", "¿Cómo digo \"Adiós\" en español ?", "Tell me something interesting about Spain", "How do I say \"Thank you\" in Spanish ?", "How do I say \"Hello\" in Spanish ?", "How do I say \"Goodbye\" in Spanish ?"],
    "italian": ["Dimmi qualcosa di interessante sull'Italia", "Come si dice \"Grazie\" in italiano ?", "Come si dice \"Ciao\" in italiano ?", "Come si dice \"Arrivederci\" in italiano ?", "Tell me something interesting about Italy", "How do I say \"Thank you\" in Italian ?", "How do I say \"Hello\" in Italian ?", "How do I say \"Goodbye\" in Italian ?"],
    "german": ["Erzähl mir etwas Interessantes über Deutschland", "Wie sage ich \"Danke\" auf Deutsch ?", "Wie sage ich \"Hallo\" auf Deutsch ?", "Wie sage ich \"Auf Wiedersehen\" auf Deutsch ?", "Tell me something interesting about Germany", "How do I say \"Thank you\" in German ?", "How do I say \"Hello\" in German ?", "How do I say \"Goodbye\" in German ?"],
    "portuguese": ["Diga-me algo interessante sobre Portugal", "Como digo \"Obrigado\" em português ?", "Como digo \"Olá\" em português ?", "Como digo \"Adeus\" em português ?", "Tell me something interesting about Portugal", "How do I say \"Thank you\" in Portuguese ?", "How do I say \"Hello\" in Portuguese ?", "How do I say \"Goodbye\" in Portuguese ?"],
    "japanese": ["日本について面白いことを教えてください", "日本語で「ありがとう」と言うにはどうすればいいですか？", "日本語で「こんにちは」と言うにはどうすればいいですか？", "日本語で「さようなら」と言うにはどうすればいいですか？", "Tell me something interesting about Japan", "How do I say \"Thank you\" in Japanese ?", "How do I say \"Hello\" in Japanese ?", "How do I say \"Goodbye\" in Japanese ?"],
  }

  connect() {
    this.renderOpeners()
  }

  renderOpeners() {
    if(!this.languageValue) return

    let language = this.languageValue.toLowerCase();

    // use openers or questions depending on stage value

    if (this.stageValue == "question") {
      if (language in this.constructor.questions) {
        let questions = this.constructor.questions[language]
        this.wrapperTarget.innerHTML = questions.map(question => this.openerHtml(question)).join("")
      }

      return;
    } 

    if(this.stageValue == "opening") {
      if (language in this.constructor.openers) {
        let openers = this.constructor.openers[language]
        this.wrapperTarget.innerHTML = openers.map(opener => this.openerHtml(opener)).join("")
      }

      return;
    }

    
    if (language in this.constructor.openers) {
      let openers = this.constructor.openers[language]
      this.wrapperTarget.innerHTML = openers.map(opener => this.openerHtml(opener)).join("")
    }
    
  }

  openerHtml(openerText) {
    return `<div 
              class="tw-px-5 tw-w-full tw-py-5 tw-rounded-lg tw-border-2 tw-border-pink-dark tw-cursor-pointer hover:tw-bg-sky-50 tw-select-none tw-animate-border-pulse" data-action="click->conversation-opener#populate">
              <div data-target="conversation-opener.wrapper">
                ${openerText}
              </div>
            </div>`
  }

  populate(event) {
    this.contentTarget.value = event.target.innerText

    this.contentTarget.dispatchEvent(new Event("input"))
  }
}
