import React from "react";
import PropTypes from "prop-types";
import MyContext from './MyContext';
import NextButton from './NextButton';

class FreeTextQuestion extends React.Component {
  render () {
    const {
      answer,
      handleChange,
      feedback,
      checkAnswer,
      lastQuestion,
      handleNext,
      nextButtonText,
      showNext,
      textarea
    } = this.props;

    return (
      <div className="new-input-container grid-x align-center">
        <div className="cell large-10">
          {textarea &&
            <textarea
              className='lf-input'
              value={answer}
              onChange={handleChange}
              placeholder={'Type Answer here...'}
              rows={5}
            />  
          }
          {!textarea &&
            <input
              type="text"
              className='lf-input'
              value={answer}
              onChange={handleChange}
              placeholder={'Type Answer here...'}
            />
          }
        </div>

        <div
          className={`cell medium-order-2 large-order-3 lf-feedback-flash toggle-trans ${feedback ? '' : 'trans'}`}
        >
          <p className='h4'>{feedback}</p>
        </div>

        <button
          className='button medium-order-3 large-order-2 lf-submit-button cell small-3 large-2'
          onClick={() => checkAnswer(answer)}
          disabled={!answer}
        >
          <img src={this.context.images.submit} alt="submit"/>
        </button>

        <NextButton
          visible={showNext}
          last={lastQuestion}
          onClick={handleNext}
          text={nextButtonText()}
        />
      </div>
    );
  }
}

FreeTextQuestion.propTypes = {
  handleChange: PropTypes.func,
  answer: PropTypes.string,
  feedback: PropTypes.string,
  checkAnswer: PropTypes.func,
  lastQuestion: PropTypes.bool,
  handleNext: PropTypes.func,
  nextButtonText: PropTypes.func
};

FreeTextQuestion.contextType = MyContext;

export default FreeTextQuestion
