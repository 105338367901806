const renderProgress = () => {
	const progressBars = document.getElementsByClassName('progress-bar');
	const amts = document.getElementsByClassName('progress-amt');
	for(let i = 0; i < progressBars.length; i++){
		const progressBar = progressBars[i];
		const amt = amts[i];
		const progress = progressBar.getAttribute('data-progress') + '%';
		progressBar.style.width = progress;
		amt.innerText = progress;
	}
}
document.addEventListener('turbo:load', renderProgress);
