
export const parseLanguageName = (languageName) => {

  const languagesAndCodes = {
    'Gaelic': 'gl',
    'Japanese': 'ja',
    'Portuguese': 'pt',
    'Spanish': 'es',
    'German': 'de',
    'French': 'fr',
    'Italian': 'it',
    'English': 'en'
  };

  return languagesAndCodes[languageName] || 'en';
}