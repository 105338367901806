import React from "react"
import PropTypes from "prop-types"
class NextExerciseButton extends React.Component {
  render () {
    if(this.props.nextExerciseId){
      return (
        <button
          className={`lf-submit-button button lf-next-button ${this.props.klass}`}
          onClick={() => window.location = '/exercises/' + this.props.nextExerciseId}
        >
          Next Exercise
        </button>
      );
    } else return null;
  }
}

NextExerciseButton.propTypes = {
  klass: PropTypes.string,
  nextExerciseId: PropTypes.number
};
export default NextExerciseButton
