import * as ajax from './ajax'
import * as vis from './visibility'

document.addEventListener('turbo:load', () => {
  const getOrders = document.querySelector('#get-orders')
  if(getOrders){
    setTimeout(requestOrders, 1000)
  }
})

const requestOrders = () => {
  const urlElements = window.location.href.split('/');
  const userId = urlElements[urlElements.length - 2]
  
  ajax.getJSON(
      `/users/${userId}/orders`,
      (res) => {
        document.querySelector('#text').innerText = setText(res);
        document.querySelector('#icon').classList = "fa fa-check fa-5x mmb";

        vis.showById('redirect-message')
        setTimeout(
          () => window.location = `/users/${userId}/dashboard`,
          1000
        );
      },
      () => {
        document.querySelector('#text').innerText = "We couldn't fetch your Courses just now.";
        vis.showById('redirect-message')
      }
  );
}

const setText = (res) => {
  let text = '';
  if(res["new"] > 0){
    text = text.concat(`Found ${res["new"]} Course${res["new"] > 1 ? 's' : ''}.`);
  }
  if(res["synced"] > 0){
    text = text.concat(` Syncing ${res["new"]} Course${res["new"] > 1 ? 's' : ''}.`);
  }
  if(res["new"] === 0 && res["synced"] === 0) text = text.concat('All good!')
  return text;
}
