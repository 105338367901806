import React from "react"
class Progress extends React.Component {
  render () {
    return (
      <div 
        id="progress-container" 
        className="toggle-trans trans" 
        style={this.props.style}
      >
        <div className="text-center xxsmb">
            <div id="progress-bar" className="light-gray-bg border-radius  relative">
              <div id="progress-amt" className="absolute top left"></div>
            </div>
            <h6 className="nm text-center small-font" id="progress-label">0%</h6> 
        </div>
      </div>
    );
  }
}

export default Progress
