import React from "react"
import PropTypes from "prop-types"
class BackToLessonButton extends React.Component {
  render () {
    return (
      <button
        className={`lf-submit-button button lf-next-button ${this.props.klass ? this.props.klass : ''}`}
        onClick={() => window.location = '/lessons/' + this.props.lessonId + '/finish'}
      >
        Back to Lesson
      </button>
    );
  }
}

BackToLessonButton.propTypes = {
  klass: PropTypes.string,
  lessonId: PropTypes.number,
};
export default BackToLessonButton
