const square = () => {
  const squares = document.querySelectorAll('.square')
  if(squares){
    for(let i = 0; i < squares.length; i++){
      const square = squares[i];
      square.style.height = square.offsetWidth + 'px';
    }
  }
}

const setSquare = () => {
  let loaded = false;
  square()
  if(!loaded) window.addEventListener('resize', () => square());
  loaded = true;
}


export default setSquare;
