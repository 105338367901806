import React from "react"
import PropTypes from "prop-types"
import NextButton from './NextButton';
import Errors from './Errors';
import Feedback from './Feedback';
import RevealAnswer from './RevealAnswer';
import Image from './Image';
import AudioPlayer from './AudioPlayer';
import NumberLabel from "./NumberLabel";
import PreviousQuestionButton from './PreviousQuestionButton';
import * as correctStyler from '../app/correctStyler';
import * as solutions from '../app/solutions';
import redirectToExerciseOrLesson from './redirectToExerciseOrLesson';
import mergeSolutions from './mergeSolutions';

class FindTheMistake extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      solutions: this.props.solutions,
      current: 0,
      answers: [],
      feedback: '',
      errors: null,
      attempts: 0,
    };
    this.feedbackTimer = null;
    this.checkAnswer = this.checkAnswer.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.clearFeedback = this.clearFeedback.bind(this);
    this.loadPrevious = this.loadPrevious.bind(this);
  }

  componentDidMount() {
    this.loadSolutions()
  }

  loadSolutions() {
    const solution = this.state.solutions[this.getCurrent().id];
    if (solution) {
      const parsed = JSON.parse(solution.body)
      this.setState({
        answers: parsed,
      }, this.refreshFeedback)
    }
  }

  refreshFeedback() {
    this.setState({ feedback: this.feedback(this.state.answers.length > 0) })
  }

  checkAnswer(e) {
    const correct = e.target.classList.contains('wrong');
    if (correct && this.feedbackTimer) clearTimeout(this.feedbackTimer);
    this.updateUI(correct, e.target);
    const newState = this.state;
    // this will not allow 2 of the same
    if (correct && !newState.answers.includes(e.target.getAttribute('data-index'))) {
      newState.answers.push(e.target.getAttribute('data-index'));
    }
    newState.attempts = this.state.attempts + 1;
    newState.feedback = this.feedback(correct);
    this.setState(newState, () => {
      this.createAnswer();
      if (!correct) {
        this.feedbackTimer = setTimeout(this.clearFeedback, 1500);
      }
    });
  }

  clearFeedback() {
    this.setState({ feedback: '' });
  }

  updateUI(correct, element) {
    const klass = correct ? 'correct' : 'incorrect';
    element.classList.add(klass);
    // Remove incorrect class
    if (!correct) {
      setTimeout(
        () => {
          element.classList.remove('incorrect');
        },
        1500
      );
    }
  }


  allComplete() {
    return this.state.answers.length === this.getCurrent().wrong_indices.length;
  }



  feedback(correct) {
    if (this.allComplete()) {
      return this.correctMessage();
    } else if (correct && !this.allComplete()) {
      return "Nice one. Can you find the others?"
    } else {
      return 'Oh no. Not quite right.'
    }
  }


  createAnswer() {
    solutions.create(
      {
        soluble_id: this.getCurrent().id,
        soluble_type: 'Text',
        body: this.state.answers,
        correct: this.allComplete(),
        user_id: this.props.user
      },
      (solution) => mergeSolutions(solution, this.state, this),
      (e) => {
        this.setState(
          { errors: e },
          () => { console.log(e) }
        )
      }
    )
  }

  getCurrent() {
    return this.props.texts[this.state.current]
  }


  renderText() {
    return <div className="find-the-mistakes-text-container">
      <p
        ref={this.para}
        className='lf-find-the-mistakes'
      >
        {this.parseText()}
      </p>
    </div>
  }

  // split the text into <span>s by word and wrong_indices
  parseText() {
    const text = this.getCurrent();
    let elements = [];
    let start = 0;

    text.wrong_indices.forEach((indices, i) => {
      const first = text.text.slice(start, indices[0]);

      if (first.length > 0) {
        const firstWords = this.spannifyWords(
          first,
          'lf-selectable-word',
          this.checkAnswer,
          `first-${i}`,
          // i
        );

        elements = elements.concat(firstWords);
      }
      // update start for next iteration
      start = indices[0] + indices[1];

      const wrong = this.getHiddenChars(i);
      const answered = this.state.answers.includes(i.toString());
      elements.push(
        <span
          onClick={this.checkAnswer}
          className={`lf-selectable-word wrong ${answered ? 'correct' : ''}`}
          key={'hidden-' + i}
          data-index={i}
        >
          {wrong}
        </span>
      )
    })

    // add last bit of text if there is any
    const last = text.text.slice(start, text.text.length);

    if (last.length > 0) {
      const lastWords = this.spannifyWords(
        last,
        'lf-selectable-word',
        this.checkAnswer,
        'last',
        // i
      );

      elements = elements.concat(lastWords);
    }

    return elements;
  }


  spannifyWords(sentence, klass = '', onClick = null, keyPrefix = 'word') {
    return sentence.split(' ').map((word, i) => (
      <span onClick={onClick} className={klass} key={`${keyPrefix}-${i}`}>{word} </span>
    ));
  }


  getHiddenChars(index) {
    const { text, wrong_indices } = this.getCurrent();
    const indices = wrong_indices[index]
    return text.slice(
      indices[0],
      indices[0] + indices[1]
    );
  }

  correctMessage() {
    return this.isLast() ? "Nice one! You're all done." : 'Excellent!'
  }


  isLast() {
    return this.state.current === this.props.texts.length - 1
  }

  nextButtonText() {
    const finishText = this.props.nextId ? 'Next Exercise' : 'Finish'
    return this.isLast() ? finishText : 'Next Question';
  }

  handleNext() {
    if (!this.isLast()) {
      this.next();
    } else {
      redirectToExerciseOrLesson(this.props)
    }
  }


  next(forward = true) {
    correctStyler.clearCorrectStyles();
    const change = forward ? 1 : -1;
    this.setState({
      current: this.state.current + change,
      answers: [],
      errors: null,
      feedback: '',
      attempts: 0
    }, this.loadSolutions)
  }

  manyAttempts() {
    const { attempts } = this.state;
    return attempts > 0;
  }

  loadPrevious() {
    if (this.state.current > 0) this.next(false);
  }

  render() {

    const { feedback, errors, current } = this.state;

    let nextExists = false;
    if (this.props.nextId || this.props.lessonId) nextExists = true;

    return (
      <div className='find-the-mistake text-center'>
        <NumberLabel
          current={this.state.current + 1}
          total={this.props.texts.length}
        />

        <Image imageable={this.getCurrent()} />
        <AudioPlayer audio={this.getCurrent().audio} audibleId={this.getCurrent().id} />

        {this.renderText()}

        <Feedback feedback={feedback} />

        {(!this.isLast() || nextExists) &&
          <NextButton
            onClick={this.handleNext}
            text={this.nextButtonText()}
          />
        }

        {this.getCurrent().wrong_indices.length > 0 &&
          <RevealAnswer
            buttonVisible={this.manyAttempts() || this.allComplete()}
            answers={[this.getCurrent().answer]}
            label={'The correct text reads:'}
            buttonText='See corrected text'
          />
        }

        <PreviousQuestionButton
          visible={current > 0}
          handleClick={this.loadPrevious}
        />

        <Errors errors={errors} />
      </div>
    );
  }
}

FindTheMistake.propTypes = {
  findTheMistake: PropTypes.object.isRequired,
  lessonId: PropTypes.number.isRequired,
  user: PropTypes.number.isRequired
};
export default FindTheMistake
