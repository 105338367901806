import React from "react"
import PropTypes from "prop-types"
import Modal from './Modal'
class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      isModalOpen: false,
    }
  }

  render () {
    const { image } = this.props.imageable;
    if(image) return (
      <>
        <div
          className="column align-middle main-exercise-img-container relative"
        >
          <img
            src={image.src}
            alt={image.name}
            className='main-exercise-img'
          />
          {image.name && 
            <figcaption>{image.name}</figcaption>
          }
          <button
            className="hand-on-hover button tiny smt blue-bg black"
            role="button"
            onClick={() => this.setState({isModalOpen: true})}
          >
            <i className="fa fa-expand" aria-hidden="true"></i> Expand
          </button>
        </div>

        { this.state.isModalOpen &&
          <Modal
            onModalClose={() => this.setState({isModalOpen: false})}
            outerContainerClasses="full-screen-image-container column align-spaced"
            innerContainerClasses='callout'
          >
            <img
              src={image.src}
              alt={image.name}
              className="full-width border-radius"
            />

          </Modal>
        }
      </>
    );
    else return null
  }
}

Image.propTypes = {
  imageable: PropTypes.object.isRequired,
};

export default Image
