import bodyClass from './bodyClass';

document.addEventListener('turbo:load', function(e) {
  const gameable = bodyClass('exercises') || bodyClass('collections');
  if(gameable && (bodyClass('new') || bodyClass('edit'))){
    const addButtons = document.querySelectorAll("[id*='add-']");
    for(let i = 0; i < addButtons.length; i++){
      const button = addButtons[i];
      button.addEventListener('click', setTypeAndSubmitForm)
    }
  }
});

const setTypeAndSubmitForm = (e) => {
  e.preventDefault();
  let gameableType = document.querySelector('body').classList[0];
  gameableType = gameableType.slice(0, gameableType.length -1);
  const type = e.target.getAttribute('data-add');
  document.getElementById(`${gameableType}_exercise_type`).value = type;

  const form = document.querySelector('form');

  // add a redirect to the form
  const redirectToField = document.querySelector('input#redirect_to');
  if(redirectToField){
    redirectToField.value = 'new_' + type
  }

  form.submit();
}