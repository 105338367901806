import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import NextButton from './NextButton';
import NumberLabel from './NumberLabel';
import Image from './Image';
import Audio from './Audio';
import Feedback from './Feedback';
import Errors from './Errors';
import RevealAnswer from './RevealAnswer';
import RevealClue from './RevealClue';
import PreviousQuestionButton from './PreviousQuestionButton';
import * as correctStyler from '../app/correctStyler';
import * as solutions from '../app/solutions';
import shuffle from '../app/shuffle';
import isLast from './isLast';
import * as jump from '../app/jump';
import { reorder, move } from "../app/dnd-helpers";
import redirectToExerciseOrLesson from './redirectToExerciseOrLesson'
import nextButtonText from './nextButtonText'
import mergeSolutions from './mergeSolutions'

const COMPLEX_PHRASE_LENGTH = 8;

// In componentDidMount we scramble the sentence.text and put them into
// this.state.words. These are rendered as draggable items in the
// scrambled-words-container. When a word is dropped into the
// sentence-drop-container, we remove it from this.state.words and add it to
// this.state.rows (which are rendered in the sentence-drop-container).
// The reverse is also true for putting words back.

class ArrangeASentence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // We copy Solutions from props here as they will be updated when the
      // user submits answers. We need this to move back and forth between Qs.
      solutions: this.props.solutions,
      current: 0,
      words: [],
      rows: [],
      answered: false,
      errors: null,
      feedback: '',
      loaded: false,
      attempts: 0,
      maxCharSize: this.getMaxCharsLimitForWindowWidth(window.innerWidth),
    };
    this.reset = this.reset.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.loadSolutions = this.loadSolutions.bind(this);
    this.updateMaxCharsLimit = this.updateMaxCharsLimit.bind(this);
    this.scramble = this.scramble.bind(this);
    this.loadPreviousSentence = this.loadPreviousSentence.bind(this);
  }

  componentDidMount() {
    // Depending of the sentence size it may need to span across multiple rows
    // each row needs a Droppable and corresponding state
    // we keep all that states in "rows" array of arrays
    this.fillRowsStateWithEmptyArrays(this.getSentenceLengthInChars());
    this.scramble(this.loadSolutions);
    window.addEventListener("resize", this.updateMaxCharsLimit);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateMaxCharsLimit);
  }

  // split the words into a number of rows based upon the maxCharSize
  renderRows(words){
    // to render words we need to have an array of object with each word and it
    // index depending on the position in the sentence
    const wordsArrayOfObjects = words.map((word, index) => {
      return {
        word,
        index
      }
    })

    const rows = []

    const totalLength = words.join(' ').length;

    let rowCount = this.getNumberOfRows(totalLength);

    for(let i = 0; i < rowCount; i++){
      let row = [];
      let sentence = '';
      let wordIndex = 0;
      let nextLength = 0;
      while(nextLength < this.state.maxCharSize){
        sentence += wordsArrayOfObjects[wordIndex].word;
        row.push(wordsArrayOfObjects[wordIndex]);
        // break out of this if there are no more words
        const anyMoreWords = wordsArrayOfObjects.length > (wordIndex + 1);
        if(anyMoreWords){
          nextLength = (sentence.length) + (wordsArrayOfObjects[wordIndex + 1].word.length);
          wordIndex += 1;
          sentence += ' '
        } else {
          break;
        }

      }

      rows.push(row);
      wordsArrayOfObjects.splice(0, wordIndex);
    }

    return rows;
  }

  getMaxCharsLimitForWindowWidth(windowWidth){
    let maxCharSize = 0

    if (windowWidth < 450) {
      maxCharSize = 12;
    } else if (windowWidth < 640){
      maxCharSize = 15
    } else if (windowWidth < 950){
      maxCharSize = 30
    } else {
      maxCharSize = 45;
    }

    return maxCharSize;
  }

  updateMaxCharsLimit() {
    const maxCharSize = this.getMaxCharsLimitForWindowWidth(window.innerWidth);
    const rowsNumber = this.getNumberOfRows(this.getSentenceLengthInChars(), maxCharSize);
  
    // Check if the words have already been scrambled
    if (!this.state.loaded) {
      this.setState({
        maxCharSize: maxCharSize,
        rows: new Array(rowsNumber).fill([]),
      });
    }
  }

  scramble(callback=null){
    const scrambled = shuffle(this.getCurrent().text.split(' '));
    // to make sure duplicate words do not have the same index
    // we need to create array of objects with word and its index
    // depending on word's position in the sentence.
    // It is needed for "draggableId" and "key" when draggable words are created
    // as we can not use the index from array when we are rendering them
    // (it can cause error when the draggable word is not able to drag).
    const scrambledObjects = scrambled.map((word, index) => {
      return {
        word,
        index
      }
    })

    this.setState(
      { words: scrambledObjects, loaded: true },
      () => { if(callback) callback() }
    );
  }

  loadSolutions(){
    const solution = this.state.solutions[this.getCurrent().id];
    if(solution){
      this.setState(
        {
          words: [],
          answered: true,
          rows: this.renderRows(solution.body.split(' '))
        },
        () => this.updateUI(this.isCorrect())
      )
    }
  }

  getCurrent(){
    return this.props.sentences[this.state.current];
  }

  renderDraggableWords(words){
    return words.map((word, i) => (
      <Draggable
        draggableId={`word-${word.word}-${word.index}`}
        key={`word-${word.word}-${word.index}`}
        index={i}
        >
        {(provided, snapshot) => (
          <p
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`word ${snapshot.isDragging ? 'shadow lighten' : ''}`}
            style={{
              ...provided.draggableProps.style,
              // opacity: snapshot.isDragging ? '0.9' : 1
            }}
          >
            {word.word}
          </p>
        )}
      </Draggable>
    ))
  }

  getSentenceLengthInChars(){
    return this.getCurrent().text.length
  }

  getNumberOfRows(sentenceLength, maxCharSize=this.state.maxCharSize){
    // round rows up or down as needed
  
    let rowCount = sentenceLength / maxCharSize;
    const isRemainder = rowCount - Math.floor(rowCount) > 0;
    if(isRemainder) rowCount = Math.ceil(rowCount)
    else rowCount = Math.floor(rowCount);

    return rowCount;
  }

  createDroppableAreas(sentenceLength){
    const numberOfDroppablesToCreate = this.getNumberOfRows(sentenceLength);

    let droppables = []

    for (let index = 0; index < numberOfDroppablesToCreate; index++) {
      droppables.push(
        <Droppable
          droppableId={`answersDroppable-${index}`}
          direction="horizontal"
          key={`answersDroppable-${index}`}  >
            {(provided) => (
              <div
                ref={provided.innerRef}
                className="text-left sentence-drop-area row"
              >
                {this.renderDraggableWords(this.state.rows[index])}
                {provided.placeholder}
              </div>
            )}
        </Droppable>
      )
    }

    return droppables;
  }

  fillRowsStateWithEmptyArrays(sentenceLength){
    const rowsNumber = this.getNumberOfRows(sentenceLength);
    this.setState({ rows: new Array(rowsNumber).fill([]) })
  }

  // Droppable name looks like 'answersDroppable-0'
  // We need the number to get index of the droppable words in the rows array
  // of arrays
  getIndexFromDroppableName(name){
    return parseInt(name.match(/\d+/)[0]); // String.match returns an array
  }

  getList = id => {
    if (id.includes('answersDroppable')){
      const droppableNumber = this.getIndexFromDroppableName(id);
      return this.state.rows[droppableNumber];
    } else {
      return this.state.words;
    }
  }

  onDragEnd = result => {
    const { destination, source} = result;

    if(!destination){
      // element dropped outside droppable area
      return;
    }

    let newState = Object.assign({}, this.state);

    // check if draggable changed a droppable area
    if( source.droppableId === destination.droppableId ){
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );


      if (source.droppableId.includes('answersDroppable')) {
        const droppableNumber = this.getIndexFromDroppableName(source.droppableId);
        newState.rows[droppableNumber] = items;
      } else  {
        newState.words = items;
      }

    } else {

      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
        );

      for (const key in result) {
        if (key.includes('answersDroppable')) {
          const droppableNumber =  this.getIndexFromDroppableName(key);
          newState.rows[droppableNumber] = result[key];
        } else  {
          newState.words = result.wordsDroppable
        }
      }
    }

    this.setState(
      newState,
      this.checkAnswer
    );
  };


  checkAnswer(){
    // If there are no words left to put in...
    if(this.state.words.length === 0){
      this.setState({ answered: true, attempts: this.state.attempts + 1 });
      this.updateUI(this.isCorrect());
      this.createAnswer()
    }
  }

  handleNext(){
    if(!isLast(this.state.current, this.props.sentences)){
      this.next();
    } else {
      redirectToExerciseOrLesson(this.props)
    }
  }

  submitButtonText(){
    return isLast(this.state.current, this.props.sentences) ? 'Back to Lesson' : 'Next Question';
  }


  feedback(){
    return this.isCorrect() ? this.correctMessage() : 'Oh no. Try again';
  }


  updateUI(correct){
    correctStyler.clearCorrectStyles();
    const input = document.querySelector('.sentence-drop-area-container');
    if(input) correctStyler.style('.sentence-drop-area-container', correct);
    if(!correct) jump.jumpById('reset-button');
    this.setState({ feedback: this.feedback() })
  }


  createAnswer(){
    solutions.create(
      {
        soluble_id: this.getCurrent().id,
        soluble_type: 'Sentence',
        body: this.getAnswerFromRowsState(),
        correct: this.isCorrect(),
        user_id: this.props.user
      },
      (solution) => mergeSolutions(solution, this.state, this),
      (e) => {
        this.setState(
          {errors: e},
          () => { console.log(e) }
        )
      }
    )
  }

  getAnswerFromRowsState(){
    let answer = '';
    for (const row of this.state.rows) {
      row.forEach((word) => answer += `${word.word} `);
    }
    return answer.trim()
  }

  isCorrect(){
    return this.getAnswerFromRowsState() === this.getCurrent().text;
  }


  next(forward=true){
    correctStyler.clearCorrectStyles();
    const change = forward ? 1 : -1;
    const rowsNumber = this.getNumberOfRows(this.props.sentences[this.state.current + change].text.length)

    this.setState(
      {
        current: this.state.current + change,
        words: [],
        rows: new Array(rowsNumber).fill([]),
        answered: false,
        errors: null,
        feedback:  '',
        loaded: false,
        attempts: 0,
      },
      () => { this.scramble(this.loadSolutions) }
    )
  }


  correctMessage(){
    return isLast(this.state.current, this.props.sentences) ? "Nice one! You're all done." : 'Excellent!'
  }


  reset(){
    correctStyler.clearCorrectStyles();

    const rowsNumber = this.getNumberOfRows(this.getSentenceLengthInChars());

    this.setState(
      {
        feedback: '',
        answered: false,
        rows: new Array(rowsNumber).fill([]),
      },
      this.scramble
    )
  }

  requiredAttemptCount(){
    return this.getCurrent().text.split(' ').length > COMPLEX_PHRASE_LENGTH ? 0 : 1;
  }

  loadPreviousSentence(){
    if(this.state.current > 0) this.next(false);
  }

  render () {
    const {
      words,
      loaded,
      feedback,
      current,
      answered,
      errors,
      attempts
    } = this.state;

    const { sentences, nextId, lessonId } = this.props;

    // Return nothing until words are ready
    if(!loaded) return null;

    const lastSentence = isLast(this.state.current, this.props.sentences);

    let nextExerciseOrLessonExists = false;
    if(nextId || lessonId) nextExerciseOrLessonExists = true;
    // Show the Next button if there are more Sentences or there
    // is another Exercise or Lesson to go to.
    let showNext = answered && (!lastSentence || nextExerciseOrLessonExists)

    return (
      <div className="arrange-a-sentence">
        <NumberLabel current={current + 1} total={sentences.length}/>
        <Image imageable={this.getCurrent()} />
        <Audio audible={this.getCurrent()} />

        <label className={"help-label"}>
          Drag the words to form a sentence
        </label>

        <button
          className="button tiny"
          onClick={this.reset}
          id='reset-button'
        >
          Reset
        </button>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="sentence-drop-area-container">
            { this.createDroppableAreas(this.getSentenceLengthInChars())}
          </div>

          <Droppable
            droppableId="wordsDroppable"
            direction="horizontal"
          >
            {(provided) => (
              <div>
                <div ref={provided.innerRef}{...provided.droppableProps} className="scrambed-words-container wrap">
                  {this.renderDraggableWords(words)}
                </div>
                {provided.placeholder}
              </div>

            )}
          </Droppable>
        </DragDropContext>

        <Feedback feedback={feedback} />

        <NextButton
          visible={showNext}
          onClick={this.handleNext}
          text={nextButtonText(this.props, lastSentence)}
        />

        {this.getCurrent().clue &&
          <RevealClue clue={this.getCurrent().clue} />
        }

        <RevealAnswer
          buttonVisible={attempts > 0 && !this.isCorrect()}
          answers={[this.getCurrent().text]}
        />

        <PreviousQuestionButton
          visible={current > 0}
          handleClick={this.loadPreviousSentence}
        />

        <Errors errors={errors} />

      </div>
    );
  }
}

ArrangeASentence.propTypes = {
  arrangeASentence: PropTypes.object,
  sentences: PropTypes.array,
  lessonId: PropTypes.number,
  user: PropTypes.number
};

export default ArrangeASentence
