import * as vis from "./visibility";

// all fields id in the form that are using cocoon
const fieldsIds = [
  "image-field",
  "video-field",
  "audio-field",
  "uploads-field",
  "gameable-fields",
];

// get all nested fields for a given element
const nestedFieldsFor = (element) => {
  return element.getElementsByClassName("nested-fields");
};
// check if the given element has any nested fields
const hasNestedFieldsFor = (element) => {
  return nestedFieldsFor(element).length > 0;
};

// check if the given element has any nested fields that are not marked for deletion
const hasNoHiddenNestedFields = (element) => {
  const nestedFields = element.getElementsByClassName("nested-fields");
  return Array.from(nestedFields).some((field) => {
    return field.style.display !== "none";
  });
};

document.addEventListener("turbo:load", () => {
  if (!document.querySelector("#collection-form")) return;

  $(document).on("cocoon:after-insert", (e) => {
    fieldsIds.map((id) => {
      if (e.target.id !== id) {
        vis.hideById(id);
      }
    });
  });

  $(document).on("cocoon:after-remove", (e) => {
    // cocoon remove action will add display:none to the removed field
    // if the target has nested fields and some of them are not display:none return from the
    // function as we don't want to show the fields yet.

    if (hasNestedFieldsFor(e.target) && hasNoHiddenNestedFields(e.target))
      return;
    fieldsIds.map((id) => vis.showById(id));
  });
});
