import React from "react"
import PropTypes from "prop-types"
import MyContext from './MyContext';
class MyTextInputAndSubmit extends React.Component {
  render () {
    return (
      <div className='my-text-input-and-submit'>
        <input
          type="text"
          className='lf-input'
          value={this.props.value}
          onChange={this.props.onChange}
          placeholder={'Type Answer here...'}
        />

        <button
          className='button lf-submit-button'
          onClick={() => this.props.handleSubmit(this.props.value)}
          disabled={!this.props.value}
        >
          <img src={this.context.images.submit} alt="submit"/>
        </button>
      </div>
    );
  }
}

MyTextInputAndSubmit.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func
};

MyTextInputAndSubmit.contextType = MyContext;

export default MyTextInputAndSubmit
