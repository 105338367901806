import React from "react";
import PropTypes from "prop-types";
import Modal from './Modal'

class RevealClue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  render () {
    const { clue, buttonText } = this.props;
    const { visible } = this.state;

    return (
      <div className='mmt'>
        <button
          className='italic block margin-auto hand-on-hover light grow'
          onClick={() => this.setState({ visible: true })}
        >
          {buttonText}
        </button>

        {visible &&
          <Modal
            onModalClose={() => this.setState({visible: false})}
          >
            {clue}
          </Modal>}
      </div>
    );

  }
}

RevealClue.propTypes = {
  clue: PropTypes.string.isRequired,
  buttonText: PropTypes.string
};

RevealClue.defaultProps = {
  buttonText: 'See a Clue'
};



export default RevealClue;
