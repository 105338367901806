const redirectToExerciseOrLesson = (props) => {
  let dest = 'lessons';
  let id = props.lessonId;
  if(props.nextId){
    dest = 'exercises';
    id = props.nextId;
  }
  let url = '/' + dest + '/' + id;
  if(!props.nextId) url += '/finish'
  window.location = url;
}

export default redirectToExerciseOrLesson;
