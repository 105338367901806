import React from "react"
import PropTypes from "prop-types"
import square from "../app/square"

class FetchList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
       items:  []
    }
    this.container = React.createRef();
  }

  componentDidMount() {
    this.setItems();
  }

  setItems(){
    this.setState({ items: this.props.items }, square)
  }
  
  render () {
    const items = this.state.items.map((item, i) => (
      <a 
        href={`/groups/${item.id}`}
        className='cell smt smb dashboard-card hover-grow small-shadow square relative'
      >
        <img src={item.image} alt={item.name} />
        <div className="inner-card absolute grid-y align-center-middle">
          <h4 className="bold text-center">{item.name}</h4>
        </div>
      </a>
    ))

    return (
      <div 
        className={`grid-x align-center ${this.props.container_class}`}
        ref={this.container}
      >
        {items}
      </div>
    );
  }
}

FetchList.propTypes = {
  url: PropTypes.string
};
export default FetchList
