import React from "react"
import PropTypes from "prop-types"
import MyContext from './MyContext'

class SubmitButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    return (
      <button
        className='grid-x button'
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        <p className="hide-for-small-only hide-for-medium-only">{this.props.submitText}</p>
        <img src={this.context.images.submit} alt="submit"/>
      </button>
    );
  }
}

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

SubmitButton.contextType = MyContext;

export default SubmitButton
