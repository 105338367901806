import { Controller } from "@hotwired/stimulus"
import { specialCharactersList } from '../helpers/specialCharacters';


// Connects to data-controller="special-characters"

const modalClosedClass = 'tw-hidden';
export default class extends Controller {
  static targets = ['input', 'modal', 'characters']

  static values = {
    modalOpen: Boolean
  }


  connect() {
    this.charactersTarget.innerHTML = specialCharactersList.map(character => {
      return `<span class="tw-m-1 tw-p-1 tw-cursor-pointer tw-text-2xl tw-text-gray-500 hover:tw-text-gray-700" data-action="click->special-characters#insertCharacter">${character}</span>`
    }).join('');
    
  }

  toggleSpecialCharactersModal(e) {
    e.preventDefault();
    e.stopPropagation();
    this.modalOpenValue = !this.modalOpenValue;
  }


  closeSpecialCharactersModal(e) {
    e.preventDefault();
    e.stopPropagation();
    this.modalOpenValue = false;
  }

  openSpecialCharactersModal(e) {
    e.preventDefault();
    e.stopPropagation();
    this.modalOpenValue = true;
  }

  modalOpenValueChanged() {
    if (this.modalOpenValue) {
      this.modalTarget.classList.remove(modalClosedClass);
    } else {
      this.modalTarget.classList.add(modalClosedClass);
    }
  }

  insertCharacter(e) {
    e.preventDefault();
    e.stopPropagation();

    const input = this.inputTarget;
    const character = e.target.innerText;
    const start = input.selectionStart; // get cursor position
    const end = input.selectionEnd; // get cursor position
    const text = input.value; // get input value
    const before = text.substring(0, start); // get text before cursor
    const after = text.substring(end, text.length); // get text after cursor
    input.value = before + character + after; // insert character
    input.selectionStart = start + 1; // set cursor position after character
    input.selectionEnd = start + 1; // set cursor position after character
    input.focus(); // set focus back to input
    
  }
}
