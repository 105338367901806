import React from "react";
import PropTypes from "prop-types";
import MyContext from './MyContext';

class PreviousQuestionButton extends React.Component {
  render () {
    if(this.props.visible) return (
      <button
        className='italic block margin-auto hand-on-hover light spt previous-question-button'
        onClick={this.props.handleClick}
      >
        <img src={this.context.images.next_black} alt="back"/>
        {this.props.buttonText &&
          <span className='sml'>{this.props.buttonText}</span>
        }
        
      </button>
    );
    else return null;
  }
}

PreviousQuestionButton.propTypes = {
  handleClick: PropTypes.func,
  buttonText: PropTypes.string,
  visible: PropTypes.bool
};

PreviousQuestionButton.contextType = MyContext;

export default PreviousQuestionButton;
