function setDataIndex() {
  // check if specific form exists
  const quizForm = document.getElementById('quiz-form');
  const arrangeASentenceForm = document.getElementById('arrange-a-sentence-form');
  const copycatQuizForm = document.getElementById('copycat-quiz-form');
  const textExerciseForm = document.getElementById('text-exercise-form');
  const flashcardForm = document.getElementById('flashcard-form');
  const matchForm = document.getElementById('match-form');
  const fillInTheBlankForm = document.getElementById('fill-in-the-blank-form');
  const findTheMistakeForm = document.getElementById('find-the-mistake-form');
  const uploadsForm = document.getElementById('uploads-form');
  const collectionForm = document.getElementById('collection-form');


  if (quizForm || arrangeASentenceForm || copycatQuizForm || textExerciseForm || flashcardForm || matchForm || fillInTheBlankForm || uploadsForm || findTheMistakeForm || collectionForm){
    // get lists of  file inputs & .valid-message & .invalid-message elements
    // all those lists have the same length, so we can use same index
    // to update data-index attribute
    const fileInputs = document.querySelectorAll('#media-fields [data-index]');
    const validMessages = document.querySelectorAll('.valid-message');
    const invalidMessages = document.querySelectorAll('.invalid-message');
    for (let index = 0; index < fileInputs.length; index++) {
      fileInputs[index].setAttribute('data-index', index)
      validMessages[index].setAttribute('data-index', index)
      invalidMessages[index].setAttribute('data-index', index)
    }
  }
}

document.addEventListener('turbo:load', setDataIndex);
document.addEventListener('ajax:success', setDataIndex)

$(document).on('cocoon:after-insert', function(e) {
  setDataIndex()
})
