import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="draggable"
export default class extends Controller {
  connect() {
    this.draggable();
  }


  draggable = () => {
    const draggables = document.querySelectorAll('.draggable');
    for(let i = 0; i < draggables.length; i++){
      makeDraggable(draggables[i]);
    }
  }
  
  makeDraggable = (element) => {
    let newX = 0, newY = 0, oldX = 0, oldY = 0;
  
    const dragMouseDown = (e) => {
      e = e || window.event;
      e.preventDefault();
      oldX = e.clientX;
      oldY = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = dragElement;
    }
  
  
    const dragElement = (e) => {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      newX = oldX - e.clientX;
      newY = oldY - e.clientY;
      oldX = e.clientX;
      oldY = e.clientY;
      // set the element's new position:
      element.style.top = (element.offsetTop - newY) + "px";
      element.style.left = (element.offsetLeft - newX) + "px";
    }
  
    const closeDragElement = () => {
      // clear methods when dragging stops
      document.onmouseup = null;
      document.onmousemove = null;
    }
  
    element.onmousedown = dragMouseDown;
  
  }
}
