import React from "react";
import PropTypes from "prop-types";
import Modal from './Modal'
class RevealAnswer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  formattedAnswers() {
    return this.props.answers.join(', ')
  }

  renderLabel() {
    if (this.props.label) return this.props.label;
    else {
      return this.props.answers.length > 1 ? 'You could have said:' : 'The answer is:';
    }
  }

  renderAnswers() {
    const answers = this.props.answers.map((a, i) => (
      <p key={i} className="lead bold">{a}</p>
    ))

    if (this.props.answers.length > 1) {
      answers.splice(answers.length - 1, 0, <p>or</p>)
    }

    return answers;
  }

  render() {
    if (this.props.buttonVisible) {
      return (
        <div className='mmt'>
          <button
            className='button small reveal-answer-button'
            onClick={() => this.setState({ visible: true })}
          >
            <i className="fa fa-eye inline-block" aria-hidden="true"></i>
            {this.props.buttonText}
          </button>

          {
            this.state.visible &&
            <Modal onModalClose={() => this.setState({ visible: false })}
            >
              <p>{this.renderLabel()}</p>
              {this.renderAnswers()}

            </Modal>
          }
        </div>
      );
    } else return null;
  }
}

RevealAnswer.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  buttonVisible: PropTypes.bool,
  label: PropTypes.string,
  buttonText: PropTypes.string
};

RevealAnswer.defaultProps = {
  buttonVisible: true,
  buttonText: 'Reveal Answer'
};



export default RevealAnswer;
