import React, { Fragment } from "react"
import PropTypes from "prop-types"
import MyAudioRecorder from './MyAudioRecorder';
import FreeTextQuestion from './FreeTextQuestion';
import AudioPlayer from './AudioPlayer';
import Modal from './Modal';
import NextButton from './NextButton';
import Progress from './Progress';
import Errors from './Errors';
import RevealAnswer from './RevealAnswer';
import SpecialCharactersModal from "./SpecialCharactersModal";
import PreviousQuestionButton from './PreviousQuestionButton';
import NumberLabel from './NumberLabel';
import * as correctStyler from '../app/correctStyler';
import * as solutions from '../app/solutions';
import * as vis from '../app/visibility';
import { normalize } from '../app/normalize';
import MyUploader from '../app/MyUploader';
import mergeSolutions from './mergeSolutions';
import { parseLanguageName } from "../app/parseLanguageName";

class Flashcard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      solutions: this.props.solutions,
      languageName: this.props.languageName,
      current: 0,
      answer: '',
      answered: false,
      recordingReady: false,
      feedback: '',
      errors: null,
      attempts: 0,
      answeredCorrectly: false,
      audio: null,
      isImageExpanded: false,
    };
    this.handleNext = this.handleNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);
    this.onRecordingReady = this.onRecordingReady.bind(this);
    this.showExample = this.showExample.bind(this);
    this.isLast = this.isLast.bind(this);
    this.nextButtonText = this.nextButtonText.bind(this);
    this.loadSolution = this.loadSolution.bind(this);
    this.next = this.next.bind(this);
    this.loadPrevious = this.loadPrevious.bind(this);
  }

  componentDidMount() {
    this.loadSolution();
  }

  loadSolution() {
    const solution = this.state.solutions[this.getCurrent().id];
    if (solution) {
      const newState = this.state;
      if (this.getCurrent().audio_answer) {
        newState.audio = solution.audio;
        newState.recordingReady = true
        this.setState(newState)
      } else {
        newState.answer = solution.body;
        this.setState(newState, this.checkAnswer(false))
      }
    }
  }

  getCurrent() {
    return this.props.flashes[this.state.current]
  }

  onRecordingReady(blob) {
    this.setState(
      { recordingReady: true },
      () => this.uploadAudioAndCreateAnswer(blob)
    )
  }

  handleChange(e) {
    correctStyler.clearCorrectStyles();
    this.setState({ answer: e.target.value, feedback: '' });
  }

  checkAnswer(createAnswer = true) {
    const newState = this.state;
    newState.answered = true;
    newState.attempts = this.state.attempts + 1;
    if (this.isCorrect() && !this.state.answeredCorrectly) {
      newState.answeredCorrectly = true;
    }
    this.setState(newState);
    this.updateUI(this.isCorrect(), this.state.answer);
    if (createAnswer) this.createAnswer();
  }

  updateUI(correct) {
    correctStyler.clearCorrectStyles();
    const input = document.querySelector('.lf-input');
    if (input) correctStyler.style('.lf-input', correct);
    this.setState({ feedback: this.feedback() })
  }

  isCorrect() {
    const requireSpecialCharacters = this.getCurrent().require_special_characters;
    if (requireSpecialCharacters) return this.normalizedAnswers(requireSpecialCharacters).includes(normalize(this.state.answer));

    return this.normalizedAnswers(requireSpecialCharacters).flat().includes(normalize(this.state.answer));
  }

  normalizedAnswers(requireSpecialCharacters) {
    if (requireSpecialCharacters) return this.getCurrent().answers.map(a => normalize(a));

    // if requireSpecialCharacters is false the correct answer should have two values: one 'truly' correct and one stripped of special characters
    // therefore we return array of arrays
    return this.getCurrent().answers.map(a => [
      normalize(a, requireSpecialCharacters),
      normalize(a, !requireSpecialCharacters)
    ]);
  }

  uploadAudioAndCreateAnswer(blob) {
    const file = new File(
      [blob],
      `solution_${this.getCurrent().id}.wav`,
      { type: 'audio/wav' }
    )
    const uploader = new MyUploader(file, this.props.direct_uploads_url);
    vis.fadeInById('progress-container');
    uploader.resetProgressBar();
    uploader.upload()
      .then(blob => {
        this.createAnswer(blob.signed_id);
        vis.fadeOutById('progress-container');
      })
      .catch(error => console.log(error))
  }

  createAnswer(signedId = null) {
    const body = {
      soluble_id: this.getCurrent().id,
      soluble_type: 'Flash',
      body: this.state.answer,
      correct: this.isCorrect(),
      user_id: this.props.user
    }
    if (signedId) body.audio_attributes = {
      name: this.getCurrent().id,
      audio: signedId
    }
    solutions.create(
      body,
      (solution) => {
        mergeSolutions(solution, this.state, this);
        this.loadSolution();
      },
      (e) => this.setState({ errors: e }, () => { console.log(e) })
    )
  }

  feedback() {
    if (!this.getCurrent().audio_answer) {
      return this.isCorrect() ? this.correctMessage() : this.incorrectMessage();
    }
  }

  correctMessage() {
    return this.isLast() ? "Nice one! You're all done." : 'Excellent!'
  }

  incorrectMessage() {
    let msg = 'Oh no. Try again!';
    if (this.getCurrent().require_special_characters) {
      msg = msg.concat(" Check your spelling or make sure you use the right accents or special characters.")
    }
    return msg;
  }

  isLast() {
    return this.state.current === this.props.flashes.length - 1
  }

  handleNext() {
    if (!this.isLast()) {
      this.updateUI();
      this.next();
    } else {
      this.redirect()
    }
  }

  next(forward = true) {
    correctStyler.clearCorrectStyles();
    const change = forward ? 1 : -1;
    this.setState({
      current: this.state.current + change,
      answer: '',
      answered: false,
      recordingReady: false,
      errors: null,
      feedback: '',
      example: false,
      attempts: 0,
      answeredCorrectly: false
    }, this.loadSolution)
  }

  redirect() {
    if (this.props.nextId) {
      window.location = '/exercises/' + this.props.nextId
    } else {
      window.location = '/lessons/' + this.props.lessonId + '/finish'
    }
  }

  showExample() {
    this.setState({ example: true })
  }

  nextButtonText() {
    if (this.isLast()) {
      return this.props.nextId ? 'Next Exercise' : 'Finish';
    } else return `Next${this.getCurrent().user_answer ? ' Question' : ''}`;
  }

  loadPrevious() {
    if (this.state.current > 0) this.next(false);
  }

  getLanguageName() {
    return parseLanguageName(this.state.languageName);
  }

  render() {
    const {
      current,
      answer,
      feedback,
      recordingReady,
      errors,
      answered,
      attempts,
      answeredCorrectly,
      audio
    } = this.state;

    const { nextId, lessonId } = this.props;

    const flash = this.getCurrent();

    let nextExists = false;
    if (nextId || lessonId) nextExists = true;

    const showNext = !this.isLast() || flash.audio || nextExists;

    return (
      <div className='flashcard'>

        <NumberLabel current={current + 1} total={this.props.flashes.length} />

        <div className="flashcard-outer-container">
          <div className="rear-card" />
          <div className="flashcard-inner-container" lang={this.getLanguageName()}>
            {
              flash.image && <img src={flash.image.src} alt={flash.image.name} />
            }

            {flash.text && <p className='h1'>{flash.text}</p>}
          </div>

          {/* Only show Fullscreen button when there is an image */}
          {flash.image &&
            <button
              className="hand-on-hover black grow sm bottom right absolute"
              role="button"
              onClick={() => this.setState({ isImageExpanded: true })}
            >
              <i className="fa fa-expand" aria-hidden="true"></i>
            </button>
          }
        </div>

        {flash.image &&
          <button
            className="hand-on-hover button tiny blue-bg mmb black"
            role="button"
            onClick={() => this.setState({ isImageExpanded: true })}
          >
            <i className="fa fa-expand" aria-hidden="true"></i> Expand
          </button>
        }

        {
          this.state.isImageExpanded &&
          <Modal
            onModalClose={() => this.setState({ isImageExpanded: false })}
            outerContainerClasses="full-screen-image-container column align-spaced"
            innerContainerClasses='callout'
          >
            <img
              src={flash.image.src}
              alt={flash.image.name}
              className="full-width border-radius"
            />

          </Modal>
        }

        {!flash.user_answer &&
          <Fragment>
            <h4 className='h1'>{flash.answers[0]}</h4>
            <NextButton
              visible={!this.isLast()}
              onClick={this.handleNext}
              text={this.nextButtonText()}
            />
          </Fragment>
        }

        {flash.audio_answer && flash.user_answer &&
          <Fragment>
            <MyAudioRecorder
              onRecordingReady={this.onRecordingReady}
              recordableId={flash.id}
              audio={audio}
            />

            <Progress style={{ width: '50%', margin: 'auto' }} />

            <NextButton
              visible={!!recordingReady && showNext}
              onClick={this.getCurrent().audio ? this.showExample : this.handleNext}
              text={this.nextButtonText()}
            />
          </Fragment>
        }

        {!flash.audio_answer && flash.user_answer &&
          <Fragment>
            <FreeTextQuestion
              handleChange={this.handleChange}
              answer={answer}
              feedback={feedback}
              checkAnswer={this.checkAnswer}
              lastQuestion={this.isLast()}
              handleNext={this.handleNext}
              nextButtonText={this.nextButtonText}
              showNext={answered && showNext}
            />

            <RevealAnswer
              buttonVisible={attempts > 0 && !answeredCorrectly}
              answers={flash.answers}
            />
          </Fragment>
        }

        {this.state.example &&
          <Modal
            onModalClose={() => this.setState({ example: false })}
            outerContainerClasses="example-container"
            innerContainerClasses='example grid-x'
          >
            <p>Great! Here's what you could have said</p>

            <AudioPlayer audio={flash.audio} audibleId={flash.id} />

            {(!this.isLast() || nextExists) &&
              <NextButton
                onClick={this.handleNext}
                text={this.isLast() ? 'Finish' : 'Next'}
              />
            }
          </Modal>
        }

        {!flash.audio_answer && flash.user_answer && <SpecialCharactersModal />}

        <PreviousQuestionButton
          visible={current > 0}
          handleClick={this.loadPrevious}
        />

        <Errors errors={errors} />
      </div>
    );
  }
}

Flashcard.propTypes = {
  flashes: PropTypes.arrayOf(PropTypes.object).isRequired,
  lessonId: PropTypes.number,
  user: PropTypes.number
};

export default Flashcard
