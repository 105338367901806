// Here's some ugly callback-hellscape code to fade an element out, call setState while
// it's hidden and then fade it back in again.

const setStateAndFadeIn = (ref, that, state, callback=undefined) => {
  that.setState(
    state,
    () => {
      if(callback) callback();
      setTimeout(
        () => ref.classList.remove('transparent'),
        500
      )
    }
  )
}

const fadeInOutWhileSettingState = (ref, that, state, callback=undefined) => {
  if(ref){
    ref.classList.add('transparent');
    setTimeout(
      () => setStateAndFadeIn(ref, that, state, callback),
      500
    )
  } else {
    console.log('No ref param given to fadeInOutWhileSettingState');
  }
  
}


export default fadeInOutWhileSettingState;
