import { Controller } from "@hotwired/stimulus";



export default class extends Controller {
  static targets = ["slide", "pagination", "currentIndex", "lastIndex"];
  static values = { index: Number };

  connect() {
    console.log("hello from chat_intro_swiper_controller");
    console.log("slides ", this.slideTargets); // Corrected from slidesTargets to slideTargets
    this.indexValue = 0;
    this.slideTargets.forEach((slide, idx) => {
      console.log("slide ", slide); // Corrected the log text to be singular as well
      slide.setAttribute("data-index", idx);
      if (idx !== 0) {
        slide.classList.add("tw-hidden");
      }
    });
    
  }

  appendPagination() {
    let html =  this.slideTargets
    .map((slide, idx) => {
      return `<span class="tw-w-4 tw-h-4 tw-bg-pink-dark tw-rounded-full"></span>`;
    })
    .join("");
    
    console.log("appending html ", html);

    this.paginationTarget.innerHTML = html;
  }

  prev() {
    if (this.indexValue > 0) {
      this.indexValue--;
      this.updateSlide();
    }
  }

  next() {
    if (this.indexValue < this.slideTargets.length - 1) {
      this.indexValue++;
      this.updateSlide();
    }
  }

  async indexValueChanged() {
    this.updateSlide();
    
  }

  updateSlide() {
    this.slideTargets.forEach((slide, idx) => {
      if (idx === this.indexValue) {
        slide.classList.remove("tw-hidden");
      } else {
        slide.classList.add("tw-hidden");
      }
    });

  }
}

