// Shift the add button to after the newly created field
document.addEventListener('turbo:load', function(e) {
  $(document).on('cocoon:after-insert', function(e) {
    const button = e.currentTarget.activeElement;
    const container = button.parentElement;
    const newFields = container.nextElementSibling;
    if( container && newFields && button ) {
    container.appendChild(newFields);
    container.appendChild(button);
    }
  })
});
