import Rails from '@rails/ujs';
import _ from 'lodash';

// Store input and it's value here so we can compare the inputs value 
// to it's previous value when the user is typing.
let input;

const submitFormOnEntry = () => {
  const inputs = document.querySelectorAll('.submit-form-on-entry');
  if(inputs && inputs.length > 0){
    for(let i = 0; i < inputs.length; i++){
      const input = inputs[i];
      // Start listening for key down events on the input while input focussed
      input.addEventListener('focus', addEntryListener);
      // Stop listening when we leave the input
      input.addEventListener('blur', () => {
        input.removeEventListener('keyup', submitFormIfChanged);
      });
    }
  }
}

const addEntryListener = (e) => {
  input = e.target;
  input.addEventListener('keyup', submitFormIfChanged);
}

const submitFormIfChanged = _.debounce((e) => {
  submitForm(input);
}, 500);
const submitForm = (element) => {
  const forms = document.querySelectorAll('form[data-remote]');
  for(let i = 0; i < forms.length; i++){
    if(forms[i].contains(element)){
       Rails.fire(forms[i], 'submit');
     }
  }
}

document.addEventListener('turbo:load', submitFormOnEntry)
