import React from 'react';
import PropTypes from 'prop-types';

const NewComment = (props) => (
  <form className='lmb'>
    {/*<label
      htmlFor="body"
      className='h3 text-center'
    >
      Would you like to leave a comment about the plans?
    </label>*/}

    <textarea
      id={`comment_body_input`}
      className='smb'
      type="text"
      name="body"
      onChange={props.handleChange}
      value={props.body}
      placeholder='Write your comment here...'
      rows={3}
    />

    {/*<label
      htmlFor="commenter"
      className='text-center'
    >
      Your Name (optional)
    </label>*/}

    <input
      className='smb'
      type="text"
      id={`comment_commenter_input`}
      name="commenter"
      onChange={props.handleChange}
      value={props.commenter}
      placeholder='Leave your name (optional)...'
    />


    <button
      className="button expanded"
      onClick={props.handleSubmit}
    >
      Submit
    </button>

  </form>

);

export default NewComment;

NewComment.propTypes = {
  body: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}
