import React from "react"
import PropTypes from "prop-types"
import Errors from './Errors';
import Feedback from './Feedback';
import NextButton from './NextButton';
import AudioPlayer from './AudioPlayer';
import * as correctStyler from '../app/correctStyler';
import * as solutions from '../app/solutions';
import delayLoop from '../app/delayLoop';
import {snakify} from '../app/snakeCase';
import {removeApostrophes} from '../app/normalize';
import redirectToExerciseOrLesson from './redirectToExerciseOrLesson'
import nextButtonText from './nextButtonText'

const INTERVAL = 200;

class OldMaze extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: [],
      feedback: '',
      attempts: 0,
    };
    this.checkAnswer = this.checkAnswer.bind(this);
    this.allCorrect = this.allCorrect.bind(this);
    this.feedback = this.feedback.bind(this);
    this.revealAnswers = this.revealAnswers.bind(this);
    this.addAnswerToAnswers = this.addAnswerToAnswers.bind(this);
  }

  componentDidMount() {
    this.loadSolution()
  }

  loadSolution(){
    if(this.props.solutions){
      this.setState({ answers: JSON.parse(this.props.solutions.body) });
    }
  }

  checkAnswer(e){
    const word = e.target.dataset.word;

    this.updateUI(e.target.id, word);

    if(this.isCorrect(word)){
      const newState = this.state;
      newState.answers.push(word);
      this.setState(newState, this.createAnswer)
    } else {
      this.setState({ attempts: this.state.attempts + 1 });
    }
  }

  updateUI(id, word){
    correctStyler.style(`#${id}`, this.isCorrect(word))

    // clear incorrect after 2s
    if(!this.isCorrect(word)){
      setTimeout(
        () => correctStyler.clearStyleFor(`#${id}`),
        2000
      )
    }
  }

  parsedId(id){
    return id.split('__')[0];
  }

  feedback(){
    if(this.allCorrect()){
      return "Nice one! You're done";
    }
  }

  isCorrect(word){
    return this.props.maze.answers.includes(word);
  }

  allCorrect(){
    return this.props.maze.answers.sort().toString() === this.state.answers.sort().join(',').toString();
  }

  setWordPadding(){
    const multiplier = 2;
    const wordWidth = 1 / this.xSize()
    return Math.floor((10 * wordWidth) * multiplier) + '%';
  }

  renderWords(){
    return this.props.maze.options.map((w, i) => (
      <button
        id={`option-${(snakify(removeApostrophes(w)))}__${i}`}
        className={`word ${this.state.answers.includes(w) ? 'correct' : ''}`}
        // style={{padding: this.setWordPadding()}}
        key={'word-' + i}
        onClick={this.checkAnswer}
        data-word={w}
      >
        {w}
      </button>
    ))
  }

  createAnswer(){
    solutions.create(
      {
        soluble_id: this.props.maze.id,
        soluble_type: 'Maze',
        body: this.state.answers,
        correct: this.allCorrect(),
        user_id: this.props.user
      },
      () => {
        console.log('UserAnswer Created'); // NOTE - show some feedback here
      },
      (e) => {
        this.setState(
          {errors: e},
          () => { console.log(e) }
        )
      }
    )
  }

  xSize(){
    return this.props.maze.dimensions.split('x')[0];
  }

  revealAnswers(){
    const userAnswers = this.state.answers.map(a => a); // store current Answers
    this.setState(
      { reveal: true, answers: [] },
      () => {
        // loop through each correct answer and reveal them
        this.props.maze.answers.forEach(
          delayLoop(this.addAnswerToAnswers, INTERVAL)
        )
        // reset user answers after loop finished
        const loopLength = this.props.maze.answers.length * INTERVAL + 1000;
        setTimeout(
          () => this.setState({ answers: userAnswers, reveal: false }),
          loopLength
        )
      }
    )
  }

  addAnswerToAnswers(a){
    const newState = this.state;
    if(!newState.answers.includes()) newState.answers.push(a);
    this.setState(newState);
  }

  render () {
    let xDimension = '';

    for(let i = 0; i < this.xSize(); i++){
      xDimension = xDimension.concat('1fr ');
    }

    let nextExists = false;
    if(this.props.nextId || this.props.lessonId) nextExists = true;

    return (
      <div className="maze old text-center">
        {/* <h1>OLD!!!</h1> */}

        <AudioPlayer audio={this.props.maze.audio} audibleId={this.props.maze.id}/>

        <p className="italic lmb small-font show-for-small-only alert-bg sp border-radius">If you're on a mobile, try rotating your phone sideways. Otherwise the words can get pretty small.</p>

        <div
          className="maze-grid lmb"
          style={{
            display: 'grid',
            gridTemplateColumns: xDimension
          }}
        >
          {this.renderWords()}
        </div>

        <Feedback feedback={this.feedback()} />

        {nextExists &&
          <NextButton
            onClick={() => redirectToExerciseOrLesson(this.props)}
            text={nextButtonText(this.props)}
          />
        }

        {this.state.attempts > 1 &&
          <button
            className={`italic block margin-auto hand-on-hover light lmt reveal-answer-button ${this.state.reveal ? 'revealing ignore-click' : ''}`}
            onClick={this.revealAnswers}
          >
            {this.state.reveal ? 'Revealing...' : 'Reveal Answer'}
          </button>
        }

        <Errors errors={this.state.errors}/>
      </div>
    );
  }
}

OldMaze.propTypes = {
  maze: PropTypes.object,
  lessonId: PropTypes.number,
  user: PropTypes.number
};

export default OldMaze
