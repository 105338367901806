export const jump = (element) => {
  if(element) element.classList.add('jump-up');
  setTimeout(
    () => element.classList.remove('jump-up'),
    1500
  )
}

export const jumpById = (id) => {
  const element = document.getElementById(id);
  if(element) jump(element);
}


export default jump;
