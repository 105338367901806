import React from "react"
import PropTypes from "prop-types"
import Errors from './Errors';
import Feedback from './Feedback';
import NextButton from './NextButton';
import AudioPlayer from './AudioPlayer';
import * as correctStyler from '../app/correctStyler';
import * as solutions from '../app/solutions';
import delayLoop from '../app/delayLoop';
import { snakify } from '../app/snakeCase';
import { removeApostrophes } from '../app/normalize';
import redirectToExerciseOrLesson from './redirectToExerciseOrLesson'
import nextButtonText from './nextButtonText'

const INTERVAL = 200;

class Maze extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      correctIndices: [],
      answers: [],
      feedback: '',
      attempts: 0,
    };
    this.checkAnswer = this.checkAnswer.bind(this);
    this.allCorrect = this.allCorrect.bind(this);
    this.feedback = this.feedback.bind(this);
    this.revealAnswers = this.revealAnswers.bind(this);
    this.addAnswerToAnswers = this.addAnswerToAnswers.bind(this);
  }

  componentDidMount() {
    this.loadSolution()
  }

  loadSolution() {
    if (this.props.solutions) {
      // Solution model saves the correct_indices as a string. We must convert.
      const indices = JSON.parse(this.props.solutions.body).map(s => Number(s));
      this.setState({ correctIndices: indices });
    }
  }

  checkAnswer(e) {
    const { index } = e.target.dataset;
    this.updateUI(e.target.id, index);

    if (this.isCorrect(index)) {
      const newState = this.state;
      newState.correctIndices.push(index);
      this.setState(newState, this.createAnswer)
    } else {
      this.setState({ attempts: this.state.attempts + 1 });
    }
  }

  updateUI(id, index) {
    correctStyler.style(`#${id}`, this.isCorrect(index))

    // clear incorrect after 2s
    if (!this.isCorrect(index)) {
      setTimeout(
        () => correctStyler.clearStyleFor(`#${id}`),
        2000
      )
    }
  }

  parsedId(id) {
    return id.split('__')[0];
  }

  feedback() {
    if (this.allCorrect()) {
      return "Nice one! You're done";
    }
  }

  isCorrect(i) {
    return this.props.maze.correct_indices.split(',').includes(i);
  }

  allCorrect() {
    return this.props.maze.correct_indices.split(',').length === this.state.correctIndices.length;
  }

  setWordPadding() {
    const multiplier = 2;
    const wordWidth = 1 / this.xSize()
    return Math.floor((10 * wordWidth) * multiplier) + '%';
  }

  renderWords() {
    return this.props.maze.options.map((w, i) => (
      <button
        id={this.formattedOption(w, i)}
        className={`word ${this.state.correctIndices.includes(i) ? 'correct' : ''}`}
        key={'word-' + i}
        onClick={this.checkAnswer}
        // data-word={w}
        data-index={i}
      >
        {w}
      </button>
    ))
  }

  formattedOption(word, index) {
    const formattedWord = snakify(removeApostrophes(word))
    // remove any full stops or commas
    const formattedIndex = formattedWord.replace(/[.,]/g, '')
    return `option-${(formattedIndex)}__${index}`
  }

  createAnswer() {
    solutions.create(
      {
        soluble_id: this.props.maze.id,
        soluble_type: 'Maze',
        body: this.state.correctIndices,
        correct: this.allCorrect(),
        user_id: this.props.user
      },
      () => {
        console.log('UserAnswer Created'); // NOTE - show some feedback here
      },
      (e) => {
        this.setState(
          { errors: e },
          () => { console.log(e) }
        )
      }
    )
  }

  xSize() {
    return this.props.maze.dimensions.split('x')[0];
  }

  revealAnswers() {
    const userIndices = this.state.correctIndices.map(i => i); // store Answers
    this.setState(
      { reveal: true, correctIndices: [] },
      () => {

        // loop through each correct answer and reveal them
        const nums = this.props.maze.correct_indices.split(',').map(i => Number(i));

        this.setState(
          { correctIndices: nums },
          () => this.resetAnswers(userIndices)
        )

        // FANCY STAGGERED REVEAL - NOT WORKING
        // nums.forEach((index, i) => delayLoop(this.addAnswerToAnswers, INTERVAL))
        // // reset user answers after loop finished
        // const loopLength = this.props.maze.correct_indices.split(',').length * INTERVAL + 1000;
        // setTimeout(
        //   () => this.setState({ correctIndices: userIndices, reveal: false }),
        //   loopLength
        // )
      }
    )
  }

  resetAnswers(answers) {
    setTimeout(
      () => this.setState({ correctIndices: answers, reveal: false }),
      2000
    )
  }

  // NOT WORKING IN loop above
  addAnswerToAnswers(a) {
    const newState = this.state;
    if (!newState.correctIndices.includes()) newState.correctIndices.push(a);
    this.setState(newState);
  }


  render() {
    let xDimension = '';

    for (let i = 0; i < this.xSize(); i++) {
      xDimension = xDimension.concat('1fr ');
    }

    let nextExists = false;
    if (this.props.nextId || this.props.lessonId) nextExists = true;

    return (
      <div className="maze text-center">

        <AudioPlayer audio={this.props.maze.audio} audibleId={this.props.maze.id} />

        <p className="italic mmb small-font show-for-small-only alert-bg sp border-radius">If the words are too small, try rotating your phone.</p>

        
        <p className="italic lmb small-font show-for-small-only alert-bg sp border-radius">
          You might need to scroll sideways to see the whole maze.
        </p>

        <div
          className="maze-grid wide lmb"
          style={{
            display: 'grid',
            gridTemplateColumns: xDimension
          }}
        >
          {this.renderWords()}
        </div>

        <Feedback feedback={this.feedback()} />

        {nextExists &&
          <NextButton
            onClick={() => redirectToExerciseOrLesson(this.props)}
            text={nextButtonText(this.props)}
          />
        }

        {this.state.attempts > 0 &&
          <button
            className={`italic block margin-auto hand-on-hover light lmt button small ${this.state.reveal ? 'revealing ignore-click' : ''}`}
            onClick={this.revealAnswers}
          >
            {this.state.reveal ? 'Revealing...' : 'Reveal Answer'}
          </button>
        }

        <Errors errors={this.state.errors} />
      </div>
    );
  }
}

Maze.propTypes = {
  maze: PropTypes.object,
  lessonId: PropTypes.number,
  user: PropTypes.number
};

export default Maze
