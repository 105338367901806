import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ["content", "icon", "translatingLabel", "translation", "translated", "input"]
  static values = { messageId: String }

  buttonLoading() {
    this.iconTarget.style.display = "none";
    this.translatingLabelTarget.style.display = "inline";
  }

  buttonReset() {
    this.iconTarget.style.display = "inline";
    this.translatingLabelTarget.style.display = "none";
  }

  async translate(event) {
    event.preventDefault()
    event.stopPropagation()
    this.clearTranslation()
    this.buttonLoading()

    try {
      const translated = await this.fetchTranslation()
      this.appendTranslation(translated)
    } catch (error) {
      console.error('Translation error:', error)
      alert('Failed to translate message. Please try again.')
    } finally {
      this.buttonReset()
    }
  }

  async translateInput(event) {
    event.preventDefault()
    event.stopPropagation()
    this.clearTranslation()
    this.buttonLoading()

    try {
      const toTranslate = this.inputTarget.value;
      if (toTranslate === '') {
        this.buttonReset()
        return
      }
      const translated = await this.fetchTranslation(toTranslate)
      this.appendInputTranslation(translated)
      this.inputTarget.focus();
    } catch (error) {
      console.error('Translation error:', error)
      alert('Failed to translate message. Please try again.')
    } finally {
      this.buttonReset()
    }
  }

  clearTranslation() {
    if (this.hasTranslationTarget) {
      this.translationTarget.remove()
    }
  }

  async fetchTranslation(toTranslate = null) {
    if(toTranslate === null) {
      toTranslate = this.contentTarget.innerText
    }

    const response = await post('/translate', {
      body: JSON.stringify({
        content: toTranslate,
        message_id: this.messageIdValue
      })
    })

    if (!response.ok) {
      throw new Error('Network response was not ok.')
    }

    const data = await response.text;
    return JSON.parse(data).translated
  }

  appendTranslation(translatedText) {
    const html = `
      <div class="py-3 translation" data-translation-target="translation">
        <hr/>
        <p class='pt-2 fade-in'>${translatedText}</p>
      </div>
    `;
    this.translatedTarget.innerHTML = '';
    this.translatedTarget.innerHTML = html;
  }

  appendInputTranslation(translatedText) {
    const html = `
      <div class="tw-p-2 tw-my-5 tw-bg-emerald-500/10 tw-rounded-md" data-translation-target="translation">
        <p class="tw-m-0 tw-p-0 tw-mb-5">Translation of 
          <i>\"${this.inputTarget.value}\"</i>
        </p>
        <hr/>
        <p class='pt-2 fade-in'>${translatedText}</p>
      </div>
    `;
    this.translatedTarget.innerHTML = '';
    this.translatedTarget.innerHTML = html;
  }
}
