import React from "react"
import PropTypes from "prop-types"
import AudioPlayer from './AudioPlayer';

class Audio extends React.Component {
  render () {
    if(this.props.audible.audio) return (
      <AudioPlayer
        audio={this.props.audible.audio} audibleId={this.props.audible.id}
      />
    );
    else return null
  }
}

Audio.propTypes = {
  audible: PropTypes.object
};

export default Audio
