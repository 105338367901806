import React,{ useState, useEffect } from 'react'
import { specialCharactersList } from '../helpers/specialCharacters';
import MyContext from './MyContext';
import Modal from './Modal'

const SpecialCharactersModal = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsCopied(false), 1000)
  }, [isCopied])

  const handleClick= (e) => {
    e.preventDefault();
    const copyText = e.target.innerText;
    const tempInputDiv = document.getElementById('temporary-input-div');
    const tempInput = document.createElement('input');
    tempInput.value = copyText;
    tempInputDiv.appendChild(tempInput)
    tempInput.select()
    document.execCommand("copy")
    tempInputDiv.removeChild(tempInput)
    setIsCopied(true);
  }


  return (
    <MyContext.Consumer>
      {context => (
        <>
          <button
            className="button tiny show-special-character-btn"
            onClick={() => setModalOpen(true)}
          >
            <img id='special-chars-icon' src={context.images.special_chars_white}></img>
            Special Characters
          </button>

          {isModalOpen &&
            <Modal
              onModalClose={() => setModalOpen(false)}
              outerContainerClasses='special-characters-container'
              innerContainerClasses='special-characters-inner-container'
             >

              <div id="temporary-input-div"></div>

              <p>Click on a character to copy it to the clipboard. You can then close this popup and paste it where you like.</p>

              <div className="flex align-center-middle">
                {specialCharactersList.map((char, index) => (
                  <a
                    href='#'
                    key={`${char}-${index}-key`}
                    className="black-border pointer letter-box white-bg"
                    onClick={handleClick}
                  >
                    {char}
                  </a>
                ))}
              </div>

              <div className={`mp successful-copy-msg toggle-trans ${isCopied ? '' : 'trans'}`}>
                Copied to clipboard!
              </div>

            </Modal>
          }
        </>
      )}

    </MyContext.Consumer>
  )
}

export default SpecialCharactersModal;
