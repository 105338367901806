import React, { Fragment } from "react"
import PropTypes from "prop-types"
import MyAudioRecorder from './MyAudioRecorder';
import NextButton from './NextButton';
import FreeTextQuestion from './FreeTextQuestion';
import Modal from './Modal';
import AudioPlayer from './AudioPlayer';
import Progress from './Progress';
import Image from './Image';
import Errors from './Errors';
import RevealAnswer from './RevealAnswer';
import RichTextContainer from './RichTextContainer';
import SpecialCharactersModal from "./SpecialCharactersModal";
import * as correctStyler from '../app/correctStyler';
import * as solutions from '../app/solutions';
import { normalize } from '../app/normalize';
import * as vis from '../app/visibility';
import redirectToExerciseOrLesson from './redirectToExerciseOrLesson';
import nextButtonText from './nextButtonText';
import MyUploader from '../app/MyUploader';

class TextExercise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      solution: this.props.solutions,
      answer: '',
      errors: null,
      feedback: '',
      example: false,
      showNext: false,
      attempts: 0,
      answeredCorrectly: false,
      audio: null,
      recordingReady: false,
    };
    this.onRecordingReady = this.onRecordingReady.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNextPressed = this.handleNextPressed.bind(this);
    this.isCorrect = this.isCorrect.bind(this);
    this.createAnswer = this.createAnswer.bind(this);
    this.loadSolution = this.loadSolution.bind(this);
  }

  componentDidMount() {
    this.loadSolution();
  }

  loadSolution() {
    if (this.state.solution) {
      if (this.props.textExercise.audio_answer) {
        this.setState({
          audio: this.state.solution.audio,
          recordingReady: true
        })
      } else {
        this.setState(
          { answer: this.state.solution.body, showNext: true },
          this.checkAnswer
        )
      }
    }
  }

  handleChange(e) {
    correctStyler.clearCorrectStyles()
    this.setState({ answer: e.target.value, feedback: '' });
  }

  anyAnswers() {
    return this.props.textExercise.answers && this.props.textExercise.answers.length > 0;
  }

  handleSubmit() {
    if (this.anyAnswers()) {
      this.checkAnswer();
      this.setState({ showNext: true })
    } else if (this.props.textExercise.example) {
      this.setState({ example: true })
    } else {
      this.setState({ feedback: 'Nice one', showNext: true })
    }
    this.createAnswer();
  }


  checkAnswer() {
    this.updateUI(this.isCorrect());
    const newState = this.state;
    newState.attempts = newState.attempts + 1;
    if (!newState.answeredCorrectly && this.isCorrect()) {
      newState.answeredCorrectly = true;
    }

    newState.feedback = this.feedback()
    this.setState(newState);
  }

  updateUI(correct) {
    correctStyler.clearCorrectStyles();
    const input = document.querySelector('.lf-input');
    if (input) correctStyler.style('.lf-input', correct);
  }

  feedback() {
    return this.isCorrect() ? 'Excellent!' : this.incorrectMessage();
  }

  incorrectMessage() {
    let msg = 'Oh no. Try again!';
    if (this.props.textExercise.require_special_characters) {
      msg = msg.concat(" Check your spelling or make sure you use the right accents or special characters.")
    }
    return msg;
  }


  // always correct if there is no answer
  isCorrect() {
    if (this.anyAnswers()) {
      const requireSpecialCharacters = this.props.textExercise.require_special_characters

      return this.formattedAnswers(requireSpecialCharacters).flat().includes(normalize(this.state.answer))

    } else {
      return true;
    }
  }

  formattedAnswers(requireSpecialCharacters) {
    if (requireSpecialCharacters) {
      return this.props.textExercise.answers.map(a => normalize(a, requireSpecialCharacters))
    }

    // if requireSpecialCharacters is false the correct answer should have two values: one 'truly' correct and one stripped of special characters
    // therefore we return array of arrays
    return this.props.textExercise.answers.map(a => [normalize(a, requireSpecialCharacters), normalize(a, !requireSpecialCharacters)])
  }

  uploadAudioAndCreateAnswer(blob) {
    const file = new File(
      [blob],
      `solution_${this.props.textExercise.id}.wav`,
      { type: 'audio/wav' }
    )
    const uploader = new MyUploader(file, this.props.direct_uploads_url);
    vis.fadeInById('progress-container');
    uploader.resetProgressBar();
    uploader.upload()
      .then(blob => {
        vis.fadeOutById('progress-container');
        this.createAnswer(blob.signed_id);
      })
      .catch(error => console.log(error))
  }

  createAnswer(signedId = null) {
    const body = {
      soluble_id: this.props.textExercise.id,
      soluble_type: 'TextExercise',
      body: this.state.answer,
      correct: this.isCorrect(),
      user_id: this.props.user
    }
    if (signedId) body.audio_attributes = {
      name: this.props.textExercise.id,
      audio: signedId
    }
    solutions.create(
      body,
      (newSolutions) => {
        const solution = newSolutions[`${Object.keys(newSolutions)[0]}`];
        this.setState({ solution: solution }, this.loadSolution)
      },
      (e) => this.setState({ errors: e }, () => { console.log(e) })
    )
  }


  handleNextPressed() {
    if (this.props.textExercise.example) {
      this.setState({ example: true })
    } else {
      redirectToExerciseOrLesson(this.props);
    }
  }

  onRecordingReady(blob) {
    this.setState(
      { recordingReady: true },
      () => this.uploadAudioAndCreateAnswer(blob)
    )
  }

  render() {

    const {
      feedback,
      answer,
      showNext,
      errors,
      attempts,
      answeredCorrectly,
      audio,
      recordingReady
    } = this.state

    const { textExercise, nextId, lessonId } = this.props;

    let nextExerciseOrLessonExists = false;
    if (nextId || lessonId) nextExerciseOrLessonExists = true;

    return (
      <div className="text-exercise">
        <RichTextContainer text={textExercise.text} />
        <Image imageable={textExercise} />
        <AudioPlayer
          audio={textExercise.audio}
          audibleId={textExercise.id}
        />

        {textExercise.audio_answer &&
          <Fragment>
            <MyAudioRecorder
              onRecordingReady={this.onRecordingReady}
              recordableId={flash.id}
              audio={audio}
            />

            <Progress style={{ width: '50%', margin: 'auto' }} />

            <NextButton
              visible={(!!recordingReady && textExercise.example) || (!!recordingReady && nextExerciseOrLessonExists)}
              onClick={this.handleNextPressed}
              text={nextButtonText(this.props)}
            />
          </Fragment>
        }

        {!textExercise.audio_answer &&
          <FreeTextQuestion
            handleChange={this.handleChange}
            checkAnswer={this.handleSubmit}
            answer={answer}
            feedback={feedback}
            showNext={(showNext && textExercise.example) || (showNext && nextExerciseOrLessonExists)}
            handleNext={this.handleNextPressed}
            nextButtonText={() => nextButtonText(this.props)}
            textarea
          />
        }


        {this.state.example &&
          <Modal
            onModalClose={() => this.setState({ example: false })}
            outerContainerClasses="example-container"
            innerContainerClasses='example grid-x'
          >
            <p className='bold'>{`${this.isCorrect() ? 'Great! ' : ''}Here's what you could have said`}</p>

            <p>{textExercise.example}</p>

            {nextExerciseOrLessonExists &&
              <NextButton
                onClick={() => redirectToExerciseOrLesson(this.props)}
                text={nextButtonText(this.props)}
              />
            }

          </Modal>
        }

        {this.anyAnswers() &&
          <RevealAnswer
            buttonVisible={attempts > 0 && !answeredCorrectly}
            answers={this.props.textExercise.answers}
          />
        }

        <Errors errors={errors} />

        {!textExercise.audio_answer && <SpecialCharactersModal />}

      </div>
    );
  }
}

TextExercise.propTypes = {
  textExercise: PropTypes.object,
  lessonId: PropTypes.number,
  user: PropTypes.number
};

export default TextExercise
