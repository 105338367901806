import React from "react"
import PropTypes from "prop-types"
class Feedback extends React.Component {
  render () {
    return (
      <div
        className={`cell medium-order-2 large-order-3 lf-feedback-flash toggle-trans ${this.props.feedback ? '' : 'trans'}`}
      >
        <p className='h4'>{this.props.feedback}</p>

      </div>
    );
  }
}

Feedback.propTypes = {
  feedback: PropTypes.string
};

export default Feedback
