import React from "react"
import { Fragment } from "react";
import PropTypes from "prop-types"
import AudioPlayer from './AudioPlayer';
import Image from './Image';
import MyAudioRecorder from './MyAudioRecorder';
import NextExerciseButton from './NextExerciseButton';
import NumberLabel from './NumberLabel';
import Modal from './Modal';
import NextButton from './NextButton';
import Progress from './Progress';
import RevealAnswer from './RevealAnswer';
import RichTextContainer from './RichTextContainer';
import PreviousQuestionButton from './PreviousQuestionButton';
import isLast from './isLast';
import fadeInOutWhileSettingState from './fadeInOutWhileSettingState';
import * as solutions from '../app/solutions';
import * as vis from '../app/visibility';
import MyUploader from '../app/MyUploader';
import mergeSolutions from './mergeSolutions';

class CopycatQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      solutions: this.props.solutions,
      current: 0,
      recordingReady: false,
      example: false,
    }
    this.copycat = React.createRef();
    this.onRecordingReady = this.onRecordingReady.bind(this);
    this.loadNextQuestion = this.loadNextQuestion.bind(this);
    this.handleNextAndFinish = this.handleNextAndFinish.bind(this);
    this.redirectToLesson = this.redirectToLesson.bind(this);
    this.loadSolution = this.loadSolution.bind(this);
    this.loadPrevious = this.loadPrevious.bind(this);
  }

  componentDidMount(){
    this.loadSolution();
  }

  loadSolution(){
    // if there are any solutions,
    const solution = this.state.solutions[this.getCurrent().id]
    if(solution){
      this.setState({ audio: solution.audio, recordingReady: true })
    }
  }

  onRecordingReady(blob){
    this.setState(
      { recordingReady: true },
      () => this.uploadAudioAndCreateAnswer(blob)
    )
  }

  audioExampleAvailable(){
    return this.props.copycats[this.state.current].audio_example;
  }

  // Show the Example if there is one,
  // otherwise
  //    -    show next question
  //    or
  //    -    redirect to the next Exercise or Lesson (if no more exercises)
  handleNextAndFinish(){
    if(this.audioExampleAvailable()) this.setState({ example: true })
    else {
      if(this.isLastCopycat()) this.redirectToExerciseOrLesson()
      else this.loadNextQuestion();
    }
  }

  loadNextQuestion(forward=true){
    const change = forward ? 1 : -1;
    fadeInOutWhileSettingState(
      this.copycat.current,
      this,
      { current: this.state.current + change, example: false },
      this.loadSolution
    )
  }

  setNextButtonText(){
    const finish = this.props.nextId ? 'Next Exercise' : 'Finish'
    if(isLast(this.state.current, this.props.copycats)){
      return finish
    } else {
      return 'Next';
    }
  }

  redirectToLesson(){
    window.location = '/lessons/' + this.props.lessonId + '/finish'
  }

  // depending on whether there is another exercise...
  redirectToExerciseOrLesson(){
    if(this.props.nextId){
      window.location = '/exercises/' + this.props.nextId
    } else {
      this.redirectToLesson();
    }
  }

  isLastCopycat(){
    return isLast(this.state.current, this.props.copycats)
  }

  renderNextButtonForExamplePopup(){
    if(this.isLastCopycat() && this.props.nextId) {
      return <NextExerciseButton nextExerciseId={this.props.nextId}/>
    } else if(!this.isLastCopycat() || this.nextExerciseOrLessonExists()) {
      return (
        <NextButton
          onClick={this.isLastCopycat() ? this.redirectToLesson : this.loadNextQuestion}
          text={this.isLastCopycat() ? 'Finish' : 'Next'}
        />
      )
    }
  }

  uploadAudioAndCreateAnswer(blob){
    const file = new File(
      [blob],
      `solution_${this.getCurrent().id}.wav`,
      { type: 'audio/wav' }
    )
    const uploader = new MyUploader(file, this.props.direct_uploads_url);
    vis.fadeInById('progress-container');
    uploader.resetProgressBar();
    uploader.upload()
      .then(blob => {
        this.createAnswer(blob.signed_id);
        vis.fadeOutById('progress-container');
      })
      .catch(error => console.log(error))
  }

  createAnswer(signedId){
    solutions.create(
      {
        soluble_id: this.props.copycats[this.state.current].id,
        soluble_type: 'Copycat',
        correct: true,
        user_id: this.props.user,
        audio_attributes: {
          name: this.getCurrent().id,
          audio: signedId
        }
      },
      (solution) => {
        mergeSolutions(solution, this.state, this);
        this.loadSolution();
      },
      (e) => {
        this.setState(
          { errors: e },
          () => { console.log(e) }
        )
      }
    )
  }

  getCurrent(){
    return this.props.copycats[this.state.current];
  }

  loadPrevious(){
    if(this.state.current > 0) this.loadNextQuestion(false);
  }

  nextExerciseOrLessonExists(){
    let nextExists = false;
    if(this.props.lessonId || this.props.nextId) nextExists = true;
    return nextExists;
  }

  render () {
    const { copycats } = this.props;
    const { current, example, audio } = this.state;
    const copycat = copycats[current];
    
    return (
      <div className='copycat'>

        <NumberLabel current={current + 1} total={copycats.length}/>

        <Image imageable={copycat} />
        <RichTextContainer text={copycat.text} />

        {/* If audio given, show both audio to listen to and record button */}
        {copycat.audio &&
          <Fragment>
            <p className='nmb light small-font italic'>Listen to the audio using the left-hand button. Then record your answer using the right-hand button.</p>

            <div className='lpt grid-x align-spaced' ref={this.copycat}>

              <div>
                <AudioPlayer
                  audio={copycat.audio} audibleId={copycat.id}
                />
              </div>

              <div>
                <MyAudioRecorder
                  onRecordingReady={this.onRecordingReady}
                  recordableId={copycat.id}
                  extraControls
                  audio={audio}
                />

                <Progress style={{ width: '50%', margin: 'auto' }}/>
              </div>

            </div>
          </Fragment>
        }

        {/* If no audio given, show only record button */}
        {!copycat.audio &&
          <div className="row align-center" ref={this.copycat}>
            <span>
              <MyAudioRecorder
                onRecordingReady={this.onRecordingReady}
                recordableId={copycat.id}
                extraControls
                audio={audio}
              />

              <Progress style={{ width: '50%', margin: 'auto' }}/>
            </span>
          </div>
        }

        {(!this.isLastCopycat() || copycat.audio_example || this.nextExerciseOrLessonExists()) &&
          <NextButton
            disabled={!this.state.recordingReady}
            onClick={this.handleNextAndFinish}
            text={this.setNextButtonText()}
          />
        }
        

        {copycat.answer &&
          <RevealAnswer
            label=' '
            buttonText='See Translation/Transcript'
            answers={[copycat.answer]}
          />
        }

        <PreviousQuestionButton
          visible={current > 0}
          handleClick={this.loadPrevious}
        />

        {example &&
          <Modal
            onModalClose={() => this.setState({ example: false })}
            outerContainerClasses="example-container"
            innerContainerClasses='example grid-x'
          >
            <div id={this.props.id}>
              <p>
                Great! Here's what you could have said. How does it compare?
              </p>

              <AudioPlayer
                audio={copycat.audio_example}
                audibleId={copycat.id}
              />

              {this.renderNextButtonForExamplePopup()}

            </div>

          </Modal>
        }

      </div>
    );
  }
}

CopycatQuiz.propTypes = {
  copycat_quiz: PropTypes.object.isRequired,
  copycats: PropTypes.array.isRequired,
  lessonId: PropTypes.number,
  nextId: PropTypes.number,
  user: PropTypes.number.isRequired
};
export default CopycatQuiz
