import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-speech"
export default class extends Controller {
  static targets = [ "content" ]
  static values = {lang: String}

  connect() {
    this.lang = this.langValue || 'en-US';
    if (speechSynthesis.getVoices().length === 0) {
      speechSynthesis.onvoiceschanged = this.initializeVoices.bind(this);
    } else {
      this.initializeVoices();
    }
  }

  initializeVoices() {
    this.voices = speechSynthesis.getVoices();
    console.log(this.voices);
  }

  speak(e) {
    const utterance = new SpeechSynthesisUtterance(this.contentTarget.innerText);
    utterance.lang = this.languageToCode(this.lang);
    
    // Select the best voice based on the language
    utterance.voice = this.selectBestVoice(utterance.lang);
    console.log(utterance.voice);

    // Cancel any previous utterances
    speechSynthesis.cancel();
    speechSynthesis.speak(utterance);

    e.currentTarget.dataset.action = "click->text-speech#stop";
    e.currentTarget.innerHTML = `<i class="fa fa-stop tw-pointer-events-none"></i>`;

  
  }

  stop(e) {
    speechSynthesis.cancel();
    e.currentTarget.dataset.action = "click->text-speech#speak";
    e.currentTarget.innerHTML = `<i class="fa fa-volume-up tw-pointer-events-none"></i>`;
  }

  languageToCode(language) {
    if(!language) return 'en-US';

    switch(language) {
      case 'English':
        return 'en-US';
      case 'French':
        return 'fr-FR';
      case 'German':
        return 'de-DE';
      case 'Spanish':
        return 'es-ES';
      case 'Italian':
        return 'it-IT';
      case 'Japanese':
        return 'ja-JP';
      case 'Korean':
        return 'ko-KR';
      default:
        return 'en-US';
    }
  }


  selectBestVoice(langCode) {
    const voices = speechSynthesis.getVoices();
    // Filter all voices that match the language code
    const matchingVoices = voices.filter(voice => voice.lang === langCode);

    // Further filter to find Google voices from the matching voices
    const googleVoices = matchingVoices.filter(voice => voice.name.includes('Google'));

    // Return the first Google voice if available; otherwise, return the first matching voice
    return googleVoices.length > 0 ? googleVoices[0] : matchingVoices[0];
}
}
