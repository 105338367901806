import React from "react"
import PropTypes from "prop-types"
import MyContext from './MyContext';

class MyAudioRecorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recorderReady: false,
      readyToPlay: false,
      recording: false,
      playing: false
    };
    this.mediaRecorder = null;
    this.container = React.createRef();
    this.onError = this.onError.bind(this);
    this.toggleRecording = this.toggleRecording.bind(this);
    this.onRecordingReady = this.onRecordingReady.bind(this);
    this.onPlaybackFinished = this.onPlaybackFinished.bind(this);
    this.handleButton = this.handleButton.bind(this);
    this.deleteAudio = this.deleteAudio.bind(this);
    this.setupRecorder = this.setupRecorder.bind(this);
    this.loadAudioFromProps = this.loadAudioFromProps.bind(this);
  }

  componentDidMount() {
    if(navigator.mediaDevices.getUserMedia) {
      this.setupRecorder(this.onRecordingReady, this.onPlaybackFinished);
      this.loadAudioFromProps();
    } else {
      this.setState({error: 'This browser does not support audio recording. Please use Chrome, Safari, Firefox or Edge. If it still does not work, get in touch for help'})
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.recordableId !== this.props.recordableId) this.deleteAudio();
    if(prevProps.audio !== this.props.audio) {
      this.deleteAudio(this.loadAudioFromProps);
    }
  }

  // TODO some repetition here (see setupRecorder mediaRecorder.onstop)
  loadAudioFromProps(){
    if(this.props.audio){
      const audio = document.createElement('audio');
      audio.id = 'audio-tag'
      audio.onended = this.onPlaybackFinished;
      audio.src = this.props.audio.src;
      document.getElementById('my-audio-recorder').appendChild(audio);
      this.setState({ readyToPlay: true, recording: false })
    }
  }

  onRecordingReady(audioUrl){
    this.props.onRecordingReady(audioUrl);
    this.setState({ readyToPlay: true, recording: false })
  }

  onPlaybackFinished(){
    this.setState({ playing: false })
  }

  setupRecorder(onRecordingReady, onPlaybackFinished){
    const constraints = { audio: true };

    let onSuccess = (stream) => {
      let chunks = [];

      this.mediaRecorder = new MediaRecorder(stream);

      this.mediaRecorder.onstop = function(e) {
        const blob = new Blob(chunks, { 'type' : 'audio/wav; ' });
        chunks = [];

        // const audio = document.createElement('audio');
        // audio.id = 'audio-tag'
        // audio.onended = onPlaybackFinished;
        // const audioURL = window.URL.createObjectURL(blob);
        // audio.src = audioURL;
        // document.getElementById('my-audio-recorder').appendChild(audio);
        onRecordingReady(blob);
      }

      this.mediaRecorder.ondataavailable = function(e) {
        chunks.push(e.data);
      }

      this.setState({recorderReady: true})
    }

    navigator.mediaDevices.getUserMedia(constraints)
      .then(onSuccess, this.onError);
  }

  onError(e){
    console.log(e);
    let displayError = e;
    if(e.name.includes('NotFoundError') || e.name.includes('DevicesNotFoundError') || e.message.includes('Permission denied')){
      displayError = 'No audio devices found. Please makes sure you have a) granted your browser access to your microphone, b) are using a device with a microphone, or c) have connected an external microphone.'
    }
    this.setState({error: 'An error occured: ' + displayError});
  }

  startRecording(){
    this.setState({ readyToPlay: false, recording: true })
    this.mediaRecorder.start();
  }

  stopRecording(){
    this.mediaRecorder.stop();
  }

  buttonText(){
    if(!this.state.readyToPlay){
      return this.state.recording ? 'Stop Recording' : this.recordInstruction()
    } else {
      return this.state.playing ? 'Stop' : 'Play your Answer';
    }
  }

  recordInstruction(){
    return this.context.exercise_type === 'Copycat' ? 'Click to Record' : 'Record Your Answer';
  }

  handleButton(){
    return this.state.readyToPlay ? this.togglePlay() : this.toggleRecording();
  }

  togglePlay(){
    const audio = document.getElementById('audio-tag');
    if(audio){
      if(this.state.playing){
        this.setState({ playing: false}, () => audio.pause())
      } else {
        this.setState({ playing: true}, () => audio.play())
      }
    }
  }

  toggleRecording(){
    return this.state.recording ? this.stopRecording() : this.startRecording();
  }

  deleteAudio(callback=undefined){
    const audio = document.getElementById('audio-tag');
    if(audio) audio.remove();
    this.setState(
      { playing: false, recording: false, readyToPlay: false  },
      () => {
        if(typeof callback === 'function') callback();
      }
    )
  }

 


  render () {
    if(this.state.error) return (
      <p className='warning bold'>
        {this.state.error}
      </p>
    )

    return (
      <div className='audio-recorder-container row-or-column'>
        <div id='my-audio-recorder' className='my-audio-recorder' >
          {/* check if microphone mute */}

           
          {!this.state.recorderReady &&
            <p className='lead bold'>Loading...</p>
            
          }
          {this.state.recorderReady &&
            <button
              className={`record-button fade-in ${this.state.recording ? 'recording' : ''} ${this.state.playing ? 'playing' : ''} button`}
              onClick={this.handleButton}
            >

              <div className="inner-record-button">
                <label>
                  {this.state.recording && <div className="record-symbol"/>}
                  {this.buttonText()}
                </label>

                <img
                  className="speak-icon mml"
                  src={this.context.images.speaking}
                  alt='speaking'
                />
              </div>

            </button>
          }

        </div>

        <div className='column-or-row fn-button-container align-justify'>
          {this.context.type === 'Copycat' &&
            <button className="lf-audio-fn-button slow">
              <img
                className="turtle-icon"
                src={this.context.images.turtle_white}
                alt='turtle'
              />
              <img
                className="replay-icon"
                src={this.context.images.replay_white}
                alt='replay'
              />
            </button>
          }

          {this.state.readyToPlay &&
            <button className='lf-audio-fn-button' onClick={this.deleteAudio}>
              <img
                className="bin-icon"
                src={this.context.images.bin}
                alt='delete'
              />
            </button>
          }

          {this.context.type === 'Copycat' &&
            <button className="lf-audio-fn-button loop">
              <img
                className="replay-icon"
                src={this.context.images.replay_black}
                alt='replay'
              />
            </button>
          }
        </div>
      </div>
    );
  }
}

MyAudioRecorder.contextType = MyContext;

export default MyAudioRecorder
