let loaded = false;
let resizeObserver = null;

const square = () => {
  const records = document.querySelectorAll('.square');

  records.forEach(record => {
    if (record.offsetWidth !== 0) {
      record.style.height = `${record.offsetWidth}px`;
    } else {
      resizeObserver.observe(record);
    }
  });
};

// Initialize and set ResizeObserver once
const initResizeObserver = () => {
  if (!resizeObserver) {
    resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const record = entry.target;
        record.style.height = `${record.offsetWidth}px`;
      });
    });
  }
};

// Add window resize listener once
const setResizeListener = () => {
  if (!loaded) {
    window.addEventListener('resize', square);
    loaded = true;
  }
};

// Event setup function
const setupEvents = () => {
  initResizeObserver();
  setResizeListener();
  square();
};

// Set up square recalculations for various events
['DOMContentLoaded', 'turbo:load', 'turbo:render', 'turbo:frame-render'].forEach(event => {
  document.addEventListener(event, () => {
    console.log(`hello from square.js from ${event}`);
    setupEvents();
  });
});

// Trigger square on specific clicks
document.addEventListener('click', e => {
  if (e.target.classList.contains('trigger-square-on-click')) {
    square();
  }
});

export default square;
