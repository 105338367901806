import Rails from '@rails/ujs';

const submitFormOnChange = () => {
  console.log('submitFormOnChange')
  const inputs = document.querySelectorAll('.submit-form-on-change');

  if(inputs && inputs.length > 1){
    for(let i = 0; i < inputs.length; i++){
      const input = inputs[i];

      input.addEventListener('change', () => submitForm(input));

    }
  }
}

const submitForm = (element) => {
  const forms = document.querySelectorAll('form[data-remote]');
  for(let i = 0; i < forms.length; i++){
    if(forms[i].contains(element)){
       Rails.fire(forms[i], 'submit');
     }
  }
}

document.addEventListener('turbo:load', submitFormOnChange)
