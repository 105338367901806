import React, { Fragment } from "react"
import PropTypes from "prop-types"
import clearSelection from '../app/clearSelection';
import * as vis from '../app/visibility';
import _ from 'lodash';

class SetMistakesField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      text: '',
      hiddenIndices: [],
    };
    this.setText = this.setText.bind(this);
    this.handleSetMistake = this.handleSetMistake.bind(this);
    this.reset = this.reset.bind(this);
    this.editText = this.editText.bind(this);
    this.cntr = React.createRef();
  }

  componentDidMount() {
    const newState = this.state;
    if(this.props.text){
      newState.text = this.props.text;
      this.toggleTextFields();
    }
    if(this.props.hidden_indices){
      newState.hiddenIndices = this.sortIndices(
        JSON.parse(this.props.hidden_indices)
      );
    }
    if(this.props.answer) this.showAnswerField();
    newState.loading = false;
    this.setState(newState);
  }

  // sort into chronological order for easier processing when rendering
  sortIndices(indices){
    return _.sortBy(indices, [function(i) { return i[0]; }]);
  }

  setText(e){
    e.preventDefault();
    const textInput = this.cntr.current.parentElement.previousElementSibling;
    if(textInput && textInput.value){
      // toggle text inputs
      textInput.classList.add('hidden');
      textInput.previousElementSibling.classList.add('hidden');
      // toggle add image and audio buttons
      vis.showById('image-field')
      vis.showById('audio-field')
      this.setState({ text: textInput.value })
    }
  }

  toggleTextFields(){
    const textInput = this.cntr.current.parentElement.previousElementSibling;
    const label = textInput.previousElementSibling;
    vis.toggle(textInput);
    vis.toggle(label);
  }

  handleSetMistake(e){
    e.preventDefault();
    const { anchorOffset, focusOffset, anchorNode } = window.getSelection();

    // only execute if selection is in the right place
    if(anchorNode.parentElement.classList.contains('mistakes-selector')){
      let mouseStart = anchorOffset;
      let mouseEnd = focusOffset;

      // swap based upon which way you use the mouse
      if(mouseStart > mouseEnd){
        mouseStart = focusOffset;
        mouseEnd = anchorOffset;
      }

      const start = mouseStart;
      const length = mouseEnd - start;

      if(!this.indicesOverlap(start, length)){
        const newState = this.state;
        newState.hiddenIndices.push([start, length]);
        // sort into chronological order
        newState.hiddenIndices = this.sortIndices(newState.hiddenIndices)
        this.setState(
          newState,
          () => {
            // show answer field
            this.showAnswerField();
            this.clearSelectionAndUpdateHiddenIndiceField();
          }

        );
      } else {
        alert(
          "Invalid selection: You can't select the same word more than once."
        );
      }
    }
  }

  // TODO - REFACTOR to put the used indices in state so we don' need to calc
  // them every time.
  indicesOverlap(start, length){
    let overlap = false

    // store all the indices we have used
    const used_indices = [];
    this.state.hiddenIndices.forEach((hiddenPair) => {
      for(let i = hiddenPair[0]; i < hiddenPair[0] + hiddenPair[1]; i++){
        used_indices.push(i);
      }
    })

    const indices = []
    // indices in current word
    for(let i = start; i < start + length; i++){
      indices.push(i);
    }

    for(let i = 0; i < indices.length; i++){
      if(used_indices.includes(indices[i])){
        overlap = true;
        break;
      }
    }

    return overlap;
  }

  showAnswerField(){
    vis.show(
      this.cntr.current.parentElement.nextElementSibling.nextElementSibling
    );
  }

  clearSelectionAndUpdateHiddenIndiceField(){
    clearSelection();
    const indiceField = this.cntr.current.parentElement.nextElementSibling;
    if(indiceField.name.includes('[hidden_indices]')){
      indiceField.value = `${JSON.stringify(this.sortIndices(this.state.hiddenIndices))}`;
    }
  }

  renderMistakeDisplay(){
    return <p className='mistakes-display h1'>
      {this.parseText(this.state.text)}
    </p>
  }

  // Split the text into a series of <p> and <input> based on hidden_indices
  parseText(text){
    const elements = [];
    let start = 0;

    this.state.hiddenIndices.forEach((indices, i) => {
      // create a <p> for first section if any
      const first = text.slice(start, indices[0]);
      if(first.length > 0) elements.push(<span key={'p-' + i}>{first}</span>)

      // update start for next iteration
      start = indices[0] + indices[1];

      const mistake = text.slice(indices[0], indices[0] + indices[1])

      elements.push(<span className='mistake'>{mistake}</span>)
    })

    // add last bit of text if there is any
    const lastSection = text.slice(start, text.length);
    if(lastSection.length > 0) elements.push(<span key={'p-' + elements.length}>{lastSection}</span>)

    return elements;
  }

  reset(e, callback=undefined){
    e.preventDefault();
    this.setState(
      { hiddenIndices: [] },
      () => { if(callback) callback() }
    );
  }

  editText(e){
    e.preventDefault();
    if(confirm('This will clear all the mistakes you have highlighted!')){
      this.reset(
        e,
        () => {
          this.setState({ text: '' }, this.toggleTextFields)
        }
      );
    }
  }

  render () {
    const { text, loading } = this.state;
    return (
      <div
        ref={this.cntr}
        className='mpt'
      >

        {loading && <h2>Loading...</h2>}

        {!loading &&
          <Fragment>
            {!text &&
              <button
                onClick={this.setText} className="button">Add Text
              </button>
            }

            {text &&
              <Fragment>

                <label>
                  Now highlight the chosen words one at a time, clicking Set Word each time. You will see the
                  <span className="bold"> Selections </span>
                  below show your changes
                </label>

                <p
                  className="mistakes-selector"
                >
                  {this.state.text}
                </p>

                <nav className="row">
                  <button
                    className="button lmb"
                    style={{marginRight: '0.5rem'}}
                    onClick={this.handleSetMistake}
                  >
                    Set Word
                  </button>

                  <button
                    className="button lmb"
                    style={{marginRight: '0.5rem'}}
                    onClick={this.reset}
                  >
                    Clear All
                  </button>

                  <button
                    className="button lmb"
                    style={{marginRight: '0.5rem'}}
                    onClick={this.editText}
                  >
                    Edit Text
                  </button>
                </nav>

                <h4>Selections:</h4>

                {this.renderMistakeDisplay()}


              </Fragment>
            }
          </Fragment>
        }


      </div>
    );
  }
}

export default SetMistakesField
