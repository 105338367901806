import React from "react";
import PropTypes from "prop-types";
import { Player, BigPlayButton } from 'video-react';
import "video-react/styles/scss/video-react";

class Video extends React.Component {
  render () {
    
    let { src, item } = this.props;
    if(!src) src = item["video"]["src"];

    return (
      <div className='lmb'>
        <Player
          src={src}
          poster={this.props.item.image ? this.props.item.image.src : null}
          preload={'auto'}
          playsInline
        >
          <BigPlayButton position="center" />
        </Player>
      </div>
    );
  }
}

Video.propTypes = {
  item: PropTypes.object
};

export default Video
