import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Rails from '@rails/ujs';
import { map } from "jquery";

class MazeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: null,
      options: [],
      answers: [],
    };
    this.maze = React.createRef();
    this.generateMaze = this.generateMaze.bind(this);
    this.submit = this.submit.bind(this);
    this.addCorrectIndices = this.addCorrectIndices.bind(this);
  }

  componentDidMount() {
    const newState = this.state;
    newState.dimensions = this.props.maze.dimensions;
    newState.options = this.props.maze.options;

    // ATTEMPTED FIX
    if(this.props.maze.correct_indices && this.props.maze.correct_indices.length > 0){
      newState.answers = this.props.maze.correct_indices.split(",").map(i => parseInt(i));
    }
    // Prior to FIX
    // this.props.maze.answers.forEach((ans) => {
    //   newState.answers.push(this.props.maze.options.indexOf(ans))
    // })
    this.setState(newState);
  }

  generateMaze(e){
    e.preventDefault();
    const dimensions = document.getElementById('maze_dimensions').value
    this.setState({ dimensions: dimensions }, this.setOptions)
  }

  setOptions(){
    const newState = this.state;
    for(let i = 0; i < this.mazeSize(); i++){
      if(!newState.options[i]) newState.options.push('');
    }
    if(newState.options.length > this.mazeSize()){
      newState.options.length = this.mazeSize();
      this.clearFields();
    }
    this.setState(newState);
  }


  // Find all the options (and corresponding answers) that should be removed as
  // the maze is shortened, and add a hidden _destroy field for them to delete
  // association(s) on update.
  clearFields(assoc){
    const nameFields = document.querySelectorAll(
      "[name*='options_attributes'][name*='[name]']"
    );
    const idFields = document.querySelectorAll(
      "[name*='options_attributes'][name*='[id]']"
    );
    if(nameFields.length > 0){
      for(let i = this.mazeSize(); i < nameFields.length; i++){
        // create destroy_field for option
        const destroyOption = nameFields[i].cloneNode();
        destroyOption.value = 1
        destroyOption.name = destroyOption.name.replace('[name]', '[_destroy]');
        destroyOption.id = destroyOption.id.replace('_name', '__destroy');
        nameFields[i].parentElement.insertBefore(destroyOption, nameFields[i]);

        const answerNameField = document.querySelector(
          `[name*='answers_attributes'][value='${nameFields[i].value}']`
        );

        if(answerNameField){
          // add destroy field for answer
          const destroyAnswer = answerNameField.cloneNode();
          destroyAnswer.value = 1
          destroyAnswer.name = destroyAnswer.name.replace(
            '[name]',
            '[_destroy]'
          );
          destroyAnswer.id = destroyAnswer.id.replace('_name', '__destroy');
          answerNameField.parentElement.insertBefore(destroyAnswer, answerNameField);
        }
      }
    }
  }

  mazeForm(){
    return <ul
      className="maze-grid lmb no-style-list"
      ref={this.maze}
      style={{
        display: 'grid',
        gridTemplateColumns: this.xDimension()
      }}
    >
      {this.inputs()}
    </ul>
  }

  xSize(){
    return this.state.dimensions.split('x')[0];
  }

  ySize(){
    return this.state.dimensions.split('x')[1];
  }

  mazeSize(){
    return this.xSize() * this.ySize();
  }

  xDimension(){
    let xDimension = '';

    for(let i = 0; i < this.xSize(); i++){
      xDimension = xDimension.concat('1fr ');
    }

    return xDimension
  }

  inputs(){
    return this.state.options.map((option, i) => {
      return <li
        className='word'
        style={{ padding: '0.5rem' }}
        key={'word-' + i}
      >
        <input
          type="text"
          className='smb'
          key={'word-field-' + i}
          name={'word-field-' + i}
          value={option}
          onChange={(e) => this.handleChange(i, e)}
        />
        <label htmlFor={'word-field-' + i} className='inline smr smb'>Correct?</label>
        <input
          type="checkbox"
          className='inline smb'
          onChange={(e) => this.handleCheckbox(i, e)}
          checked={this.state.answers.includes(i)}
        />
      </li>
    })
  }


  handleChange(i, e){
    const newState = this.state;
    newState.options[i] = e.target.value;
    this.setState(newState);
  }

  handleCheckbox(i, e){
    const checked = e.target.checked;
    const newState = this.state;
    if(checked) newState.answers.push(i);
    else {
      const indexToRemove = this.state.answers.indexOf(i);
      newState.answers.splice(indexToRemove, 1)
    }
    this.setState(newState);
  }

  submit(e){
    e.preventDefault()
    this.deleteAllHiddenMazeFields();

    this.state.options.forEach((o, i) => {
      this.updateOrAddField('option', 'name', i, o);
    })

    this.addCorrectIndices()

    // DEPRECATED - we use correct_indices for Maze instead. 
    //              Keep for backwards compatibility.
    this.state.answers.forEach((a, i) => {
      this.updateOrAddField('answer', 'name', i, this.state.options[a]);
    })

    const form = document.querySelector('form');
    form.submit();
  }

  updateOrAddField(assoc, attr, index, value){
    const name = `maze[${assoc}s_attributes][${index}][${attr}]`
    const field = document.querySelector(`input[name="${name}"]`);
    if(field) field.value = value;
    else this.addHiddenField(assoc, attr, index, value);
  }


  addHiddenField(assoc, attr, index, value){
    const input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = `maze[${assoc}s_attributes][${index}][${attr}]`;
    input.value = value;
    input.classList.add('hidden-maze-field');
    document.querySelector('form').appendChild(input);
  }

  addCorrectIndices(){
    const correctIndices = document.querySelector('input[name="maze[correct_indices]"]');

    if(correctIndices) correctIndices.value = this.state.answers.join(',');
  }


  deleteAllHiddenMazeFields(){
    const fields = document.querySelectorAll('.hidden-maze-field');
    if(fields.length > 0){
      for(let i = 0; i < fields.length; i++){
        const field = fields[i];
        field.remove();
      }
    }

  }

  render () {
    return (
      <Fragment>
        <button className='button lmb' onClick={this.generateMaze}>
          {this.state.dimensions ? 'Update' : 'Generate'} Maze
        </button>

        {this.state.dimensions && this.state.options.length !== 0 &&
          <Fragment>

            <p>
              You cannot include any of the following characters: 
              <br />
              <span>{` ; : ( ) } { [ ] # _ | \ < > /`}</span>
            </p>

            {this.mazeForm()}

            <button
              onClick={this.submit}
              className="button"
            >
              Save Maze
            </button>
          </Fragment>
        }

        {!this.state.dimensions || this.state.options.length === 0 &&
          <h4>Loading</h4>
        }
      </Fragment>
    );
  }
}

export default MazeForm
