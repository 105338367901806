import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat"
export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    // this.inputTarget.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'end'
    // });
  }


  disconnect() {
  }

}
