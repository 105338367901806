import React from "react";
import PropTypes from "prop-types";
import FreeTextQuestion from './FreeTextQuestion';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import RichTextContainer from './RichTextContainer';
import SpecialCharactersModal from "./SpecialCharactersModal";
import NumberLabel from './NumberLabel';
import AudioPlayer from './AudioPlayer';
import Errors from './Errors';
import Image from './Image';
import MyContext from './MyContext';
import RevealAnswer from './RevealAnswer';
import PreviousQuestionButton from './PreviousQuestionButton';
import * as correctStyler from '../app/correctStyler';
import * as solutions from '../app/solutions';
import { normalize } from '../app/normalize';
import redirectToExerciseOrLesson from './redirectToExerciseOrLesson'
import nextButtonText from './nextButtonText';
import parseForInvalidCharacters from './parseForInvalidCharacters';
import mergeSolutions from './mergeSolutions';

class Quiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      solutions: this.props.solutions,
      current: 0,
      answer: '',
      answered: false,
      errors: null,
      feedback: '',
      attempts: 0
    };
    this.handleOptionSelect = this.handleOptionSelect.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createAnswer = this.createAnswer.bind(this);
    this.lastQuestion = this.lastQuestion.bind(this);
    this.nextButtonText = this.nextButtonText.bind(this);
    this.loadPrevious = this.loadPrevious.bind(this);
  }

  componentDidMount() {
    this.loadSolution()
  }

  loadSolution() {
    const solution = this.state.solutions[this.getCurrent().id];
    if (solution) {
      this.setState(
        { answer: solution.body },
        this.checkAnswer
      )
    }
  }

  isMultipleChoice() {
    return this.props.questions[this.state.current].multiple_choice
  }

  handleChange(e) {
    correctStyler.clearCorrectStyles()
    this.setState({ answer: e.target.value, feedback: '' });
  }

  handleOptionSelect(value) {
    this.setState({ answer: value }, this.checkAnswer)
  }

  updateUI(correct, value = undefined) {
    correctStyler.clearCorrectStyles();
    const input = document.querySelector('.lf-input');
    if (input) correctStyler.style('.lf-input', correct);
    if (value && this.isMultipleChoice()) {
      const parsedValue = parseForInvalidCharacters(value);
      const normalizedValue = normalize(parsedValue, false)
      correctStyler.style('#option-' + normalizedValue, correct);
    }
    this.setState({ feedback: this.feedback() })
  }

  feedback() {
    return this.isCorrect() ? this.correctMessage() : this.inCorrectMessage();
  }

  inCorrectMessage() {
    let msg = 'Oh no. Try again!';
    if (this.getCurrent().require_special_characters) {
      msg = msg.concat(" Check your spelling or make sure you use the right accents or special characters.")
    }
    return msg;
  }

  checkAnswer() {
    this.setState({ answered: true, attempts: this.state.attempts + 1 })
    this.updateUI(this.isCorrect(), this.state.answer);
    this.createAnswer();
  }

  createAnswer() {
    solutions.create(
      {
        soluble_id: this.props.questions[this.state.current].id,
        soluble_type: 'Question',
        body: this.state.answer,
        correct: this.isCorrect(),
        user_id: this.props.user
      },
      (solution) => mergeSolutions(solution, this.state, this),
      (e) => {
        this.setState(
          { errors: e },
          () => { console.log(e) }
        )
      }
    )
  }

  getAnswers(reqSpecialChars = false) {
    if (reqSpecialChars) return this.getCurrent().answers.map(a => normalize(a))

    // if reqSpecialChars is false the correct answer should have two values:
    // one 'truly' correct and one stripped of special characters
    // therefore we return array of arrays
    return this.getCurrent().answers.map(a => (
      [normalize(a, reqSpecialChars), normalize(a, !reqSpecialChars)]
    ));
  }

  isCorrect() {
    if (this.getCurrent().check_for_correct_answer) {
      const req = this.getCurrent().require_special_characters
      return this.getAnswers(req).flat().includes(normalize(this.state.answer));
    } else return true;
  }

  nextButtonText() {
    return this.lastQuestion() ? 'Next Exercise' : 'Next Question';
  }

  lastQuestion() {
    return this.state.current === this.props.questions.length - 1
  }

  next(forward = true) {
    correctStyler.clearCorrectStyles();
    const change = forward ? 1 : -1;
    this.setState({
      current: this.state.current + change,
      answer: '',
      answered: false,
      errors: null,
      feedback: '',
      attempts: 0
    }, this.loadSolution)
  }

  handleNext() {
    if (!this.lastQuestion()) {
      this.updateUI();
      this.next();
    } else {
      redirectToExerciseOrLesson(this.props)
    }
  }

  correctMessage() {
    if (this.getCurrent().check_for_correct_answer) {
      return this.lastQuestion() ? "Nice one! You're all done." : 'Excellent!'
    } else {
      let answer;
      if (this.getAnswers().flat().includes(normalize(this.state.answer))) {
        answer = 'Exactly as we would have said!'
      } else {
        answer = 'Nice one. Click the See Example button below to see what you could have said';
      }
      return answer;
    }
  }

  getCurrent() {
    return this.props.questions[this.state.current];
  }

  loadPrevious() {
    if (this.state.current > 0) this.next(false);
  }

  render() {
    const { questions, quiz } = this.props;
    const {
      current,
      answered,
      feedback,
      errors,
      answer,
      attempts
    } = this.state;
    const question = questions[current];

    if (!question) return <p className='italic h1 dark-gray'>No Questions</p>

    let revealText = 'See Example';
    let revealButtonVisible = attempts > 0;

    if (this.getCurrent().check_for_correct_answer) {
      revealText = 'Reveal Answer';
      revealButtonVisible = attempts > 0 && !this.isCorrect();
    }

    // Rendering of Finish button depends on whether there is another Exercise 
    // or Lesson to move to, i.e if this is an Exercise and not a Collection.
    let showNext;
    if (this.props.nextId || this.props.lessonId) showNext = !!answered;
    else showNext = !this.lastQuestion();

    return (
      <div className='quiz'>

        <RichTextContainer text={quiz.text} />
        <NumberLabel current={current + 1} total={questions.length} />

        <div className="inner-quiz xlmb">

          <Image imageable={question} />
          <AudioPlayer audio={question.audio} audibleId={question.id} />

          <label className='question'>Q. {question.text}</label>

          {this.isMultipleChoice() &&
            <MultipleChoiceQuestion
              options={question.options}
              handleOptionSelect={this.handleOptionSelect}
              feedback={feedback}
              lastQuestion={this.lastQuestion()}
              handleNext={this.handleNext}
              nextButtonText={this.nextButtonText}
              showNext={showNext}
            />
          }

          {!this.isMultipleChoice() &&
            <>
              <FreeTextQuestion
                handleChange={this.handleChange}
                answer={answer}
                feedback={feedback}
                checkAnswer={this.checkAnswer}
                lastQuestion={this.lastQuestion()}
                handleNext={this.handleNext}
                nextButtonText={() => nextButtonText(this.props, this.lastQuestion())}
                showNext={showNext}
              />

              <SpecialCharactersModal />
            </>
          }

          <RevealAnswer
            buttonVisible={revealButtonVisible}
            answers={this.getCurrent().answers}
            buttonText={revealText}
          />

          <PreviousQuestionButton
            visible={current > 0}
            handleClick={this.loadPrevious}
          />

          <Errors errors={errors} />

        </div>

      </div>
    );
  }
}

Quiz.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  quiz: PropTypes.object,
  lessonId: PropTypes.number,
  user: PropTypes.number
};

Quiz.contextType = MyContext;

export default Quiz
