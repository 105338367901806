// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'

// require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("@nathanvda/cocoon")
require('foundation-sites');

// We need to restart Foundation after some AJAX calls, so we wrap this in a 
// method and export it at the bottim of the file.

const startFoundation = () => {
  $(function() { $(document).foundation(); });
}
// ['DOMContentLoaded', 'turbo:load', 'turbo:render', 'turbo:frame-render'] onPageLoad
// $(document).on('turbo:load', function() {
//   onPageLoad();
// });
['DOMContentLoaded', 'turbo:load', 'turbo:render', 'turbo:frame-render'].forEach(event => {
  document.addEventListener(event, onPageLoad);
});
require("app/analytics.js.erb");
require("app/pagy.js.erb");

// require("app/toggleExerciseType")
// require("app/showMoreAnswerFields")
require("app/toggleAddNestedRecordButton");
require("app/checkToggle");
require("app/scrollLeftRight");
require("app/renderProgress");
require("app/makeFirstTabActiveForUserDashboard");
require("app/multipleChoiceFormToggler")
require("app/addExerciseType");
require("app/shiftAddAssociationButtonAfterInsert")
require("app/direct_uploads");
require("app/setDataIndexForMediaInputs");
require("app/triggerFileAttached");
require("app/submitFormOnChange")
require("app/submitFormOnEntry")
require("app/clearRichTextField")
require("app/closeFlash")
require("app/notifications")
require("app/square")
require("app/setItemNumberInNestedForms")
require("app/getOrders")
require("app/showImagePositionFields")
require("app/sizeBySibling");
require("app/collectionsForm");
require("app/toggleAnswerFieldsByUserAnswer");
require("app/dataConfirm");

// require("app/listGridToggler"); // NO LONGER USED
// require("app/carersFontSize")
// require("app/speechBubbleTip")


require("trix")
require("@rails/actiontext")

require("@hotwired/turbo-rails")
import "controllers"

function onPageLoad() {
  ReactRailsUJS.mountComponents();
  startFoundation();
}

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

document.addEventListener('turbo:load', function(e) {
  $(document).on('turbo:load', function(e) {
    ReactRailsUJS.mountComponents();
  })
});




// EXPORTS /////////////////////////////////////////////////////////////////////

// Occasionally we need to call methods in our js.erb view, so we export here.

import square from '../app/square'

export {
  square,
  startFoundation
}



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
