import React from "react"
import PropTypes from "prop-types"
class RichTextContainer extends React.Component {
  render () {
    if(this.props.text){
      return  (
      <div className="exercise-rich-text-outer-container">
        <div
          className="exercise-rich-text-inner-container"
          dangerouslySetInnerHTML={{__html: this.props.text}}
        />
      </div>
      )
    } else return null;
  }
}

RichTextContainer.propTypes = {
  text: PropTypes.string
};
export default RichTextContainer
