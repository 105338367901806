import React from "react"
import PropTypes from "prop-types"

class ExampleModal extends React.Component {
  render () {
    return (
      <div
        className={`example-container toggle-trans ${this.props.visible ? '' : 'trans' }`}
      >
        <div className='example' id={this.props.id}>
          {this.props.handleClose &&
            <button
              className="close-button"
              onClick={this.props.handleClose}
            >
              &times;
            </button>
          }

          {this.props.children}
        </div>
      </div>
    );
  }
}

ExampleModal.propTypes = {
  visible: PropTypes.bool,
  id: PropTypes.string,
  handleClose: PropTypes.func
};

export default ExampleModal
