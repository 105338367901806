document.addEventListener('turbo:load', function(){

  $(document).on('cocoon:after-insert', function(e) {
    // allow only 1 add-XXXX-link at a time
    const klasses = ['add-image-link', 'add-audio-link', 'add-video-link', 'add-upload-link'];
    if(targetOfClass(e, klasses)){
      e.target.lastElementChild.classList.add('hidden')
    }
  })

  $(document).on('cocoon:after-remove', function(e) {
    // reinsert Add XXXX button after field removed
    const ids = ['image-field', 'audio-field', 'video-field', 'upload-field'];
    if (targetOfId(e, ids)){
      e.target.lastElementChild.classList.remove('hidden')
    }
  })

});

const targetOfClass = (e, klasses) => {
  let contains = false;
  for(let k of klasses){
    if(e.target.lastElementChild.classList.contains(k)){
      contains = true;
      break;
    }
  }
  return contains;
}

const targetOfId = (e, ids) => {
  let contains = false;
  for(let i of ids){
    if(e.target.id === i){
      contains = true;
      break;
    }
  }
  return contains;
}
