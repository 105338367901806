import React from "react";
import PropTypes from "prop-types";
import NextButton from './NextButton';
import Feedback from './Feedback';
import Errors from './Errors';
import * as correctStyler from '../app/correctStyler';
import * as solutions from '../app/solutions';
import _ from 'lodash';
import square from '../app/square';
import redirectToExerciseOrLesson from './redirectToExerciseOrLesson'
import nextButtonText from './nextButtonText'


class Match extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drags: [],
      drops: [],
      errors: null,
      reveal: false,
      attempts: 0,
      squareSet: false
    };
    this.onDrop = this.onDrop.bind(this);
    this.revealAnswers = this.revealAnswers.bind(this);
  }

  // sort Match Pairs in to Drag (left) and Drops (right)
  componentDidMount() {
    this.setPairs();
  }

  componentDidUpdate(){
    if(!this.state.squareSet){
      this.setSquares();
    }
  }

  setSquares(){
    square();
    this.setState({ squareSet: true });
  }

  // sort Match Pairs in to Drag (left) and Drops (right)
  setPairs(){
    this.setState({
      drags: _.shuffle(this.props.matchPairs),
      drops: _.shuffle(this.props.matchPairs)
    }, this.loadSolutions)
  }

  loadSolutions(){
    const keys = Object.keys(this.props.solutions);
    if(keys.length > 0){
      keys.forEach(k => {
        this.addCorrectTo("drops", k);
        this.addCorrectTo("drags", k);
      })
    }
  }


  // Drag elements should show Image or text and Blur overlay if answered
  renderDrags(){
    return this.state.drags.map((mp, i) => (
      <div
        className={`cell small-10 medium-8 xlarge-6 lf-match-pair-item square ${mp.correct ? 'correct' : 'draggable'}`}
        key={`mp-draggable-${mp.id}`}
        id={`mp-draggable-${mp.id}`}
        draggable={!mp.correct}
        onDragStart={(e) => this.onDragStart(e, mp.id)}
        onTouchStart={(e) => this.onDragStart(e, mp.id)}
      >
        {this.renderElement(mp, 'a')}
      </div>
    ))
  }


  // Drag elements should show image/text and drag image if answered
  renderDrops(){
    return this.state.drops.map((mp, i) => (
      <div
        className={`cell small-10 medium-8 xlarge-6 lf-match-pair-item square drop ${mp.correct ? 'correct' : 'droppable'}`}
        key={`droppable-${i}`}
        id={`mp-droppable-${mp.id}`}
        onDragOver={mp.correct ? null : (e) => e.preventDefault()}
        onDrop={mp.correct ? null : this.onDrop}

        onTouchMove={mp.correct ? null : (e) => e.preventDefault()}
        onTouchEnd={mp.correct ? null : this.onDrop}
      >
        {this.renderElement(mp, 'b')}
        {mp.correct && this.renderUnderlay(mp)}
      </div>
    ))
  }


  // return underlay image if appropriate
  renderUnderlay(mp){
    if(mp.b_text && mp.a_image){
      return <img className="mp-underlay-image" src={mp.a_image.src} alt={mp.a_image.name}/>
    }
  }


  renderElement(mp, type){
    if(mp[`${type}_text`]){
      return <p className='ignore-click'>{mp[`${type}_text`]}</p>
    } else if(mp[`${type}_image`]){
      return <img className='ignore-click match-pair-element' src={mp[`${type}_image`].src} alt={mp[`${type}_image`].name}/>
    }
  }


  // store the id of the match pair being dragged
  onDragStart(e, id){
    e.dataTransfer.setData('drag_id', id)
  }


  // - Get the IDs of the things being dragged and dropped onto
  // - compare to see if it's correct
  // - update UI
  // - add correct to the drag/drop match pairs in state if correct
  // - create Answer if correct
  onDrop(e){
    e.preventDefault();
    const dragId = e.dataTransfer.getData('drag_id');
    let drop = e.target;
    if(drop.nodeName === 'P' || drop.nodeName === 'IMG') {
      drop = drop.parentElement;
    }

    const parsedDropId = drop.id.split('-').reverse()[0];

    const correct = this.isCorrect(dragId, parsedDropId);
    this.updateUI(correct, drop.id);

    if(correct){
      this.addCorrectTo("drops", drop.id);
      this.addCorrectTo("drags", drop.id);
      this.createAnswer(parsedDropId)
    }

    this.logAttempt()
  }

  logAttempt(){
    this.setState({ attempts: this.state.attempts + 1 });
  }


  // Style the thing that is dropped onto, removing incorrect style after 1s
  updateUI(correct, id){
    correctStyler.style(`#${id}`, correct);
    if(!correct){
      setTimeout(
        () => correctStyler.clearStyleFor(`#${id}`),
        1000
      );
    }
  }


  // compare drag/drop ids
  isCorrect(drag, drop){
    return drag === drop;
  }


  // add correct attr to the right matchPair
  addCorrectTo(key, id){
    const newState = this.state;
    for(let i = 0; i < newState[key].length; i++){
      const el = newState[key][i];
      if(el.id.toString() === id.split('-').reverse()[0]){
        el["correct"] = true;
        break;
      }
    }
    this.setState(newState);
  }


  // Create a correct answer for the MatchPair
  createAnswer(mpId){
    solutions.create(
      {
        soluble_id: mpId,
        soluble_type: 'MatchPair',
        correct: true,
        user_id: this.props.user
      },
      () => {
        console.log('UserAnswer Created'); // NOTE - show some feedback here
      },
      (e) => {
        this.setState(
          {errors: e},
          () => { console.log(e) }
        )
      }
    )
  }


  loading(){
    return this.state.drags.length === 0 && this.state.drops.length === 0;
  }

  feedback(){
    if(this.allCorrect()){
      return "Nice one! You're done";
    }
  }

  allCorrect(){
    let allCorrect = true;
    for(let i = 0; i < this.state.drops.length; i++){
      if(!this.state.drops[i].correct){
        allCorrect = false;
        break;
      }
    }
    return allCorrect;
  }


  revealAnswers(){
    // Store which drag and drop indexes are correct
    const correctDrags = []
    const correctDrops = []
    this.state.drags.forEach((drag, i) => {
      if(drag.correct) correctDrags.push(i);
    })
    this.state.drops.forEach((drop, i) => {
      if(drop.correct) correctDrops.push(i);
    })

    // Set all drags and drops to correct to show answers, for 2s
    const drops = document.querySelectorAll('.drop');
    this.setState(
      { reveal: true },
      () => {
        drops.forEach((drop) => {
          this.addCorrectTo("drops", drop.id);
          this.addCorrectTo("drags", drop.id);
        })
        setTimeout(
          () => this.resetDragsAndDrops(correctDrags, correctDrops),
          2000
        )
      }
    )
  }

  // use list of correct Drag and Drop indexes to reset to users answers
  resetDragsAndDrops(correctDrags, correctDrops){
    const newState = this.state;
    newState.drags.forEach((drag, i) => {
      if(!correctDrags.includes(i)) drag.correct = false;
    })
    newState.drops.forEach((drop, i) => {
      if(!correctDrops.includes(i)) drop.correct = false;
    })
    newState.reveal = false;
    this.setState(newState)
  }

  render () {

    const { errors, attempts } = this.state;

    if(this.loading()) return <p>Loading...</p>


    return (
      <div className='match text-center'>
        <div className="grid-x align-justify lmb">
          <div className="cell small-6 xlarge-5 grid-x align-center grid-margin-x grid-margin-y">
            {this.renderDrags()}
          </div>

          <div className="cell small-6 xlarge-5 grid-x align-center grid-margin-x grid-margin-y">
            {this.renderDrops()}
          </div>
        </div>

        <Feedback feedback={this.feedback()} />

        <NextButton
          disabled={attempts < 1}
          onClick={() => redirectToExerciseOrLesson(this.props)}
          text={nextButtonText(this.props)}
        />

        {attempts > 1 &&
          <button
            className={`italic block margin-auto hand-on-hover light lmt reveal-answer-button ${this.state.reveal ? 'revealing ignore-click' : ''}`}
            onClick={this.revealAnswers}
          >
            {this.state.reveal ? 'Revealing...' : 'Reveal Answer'}
          </button>

        }

        <Errors errors={errors} />
      </div>

    );
  }
}

Match.propTypes = {
  matchPairs: PropTypes.array,
  match: PropTypes.object,
  lessonId: PropTypes.number,
  user: PropTypes.number
};
export default Match
