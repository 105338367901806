import React from "react";
import PropTypes from "prop-types";
import Quiz from './Quiz';
import Flashcard from './Flashcard';
import BlanksGrid from './BlanksGrid';
import CopycatQuiz from './CopycatQuiz';
import FillInTheBlank from './FillInTheBlank';
import FindTheMistake from './FindTheMistake';
import Maze from './Maze';
import OldMaze from './OldMaze';
import MatchImageAndText from './MatchImageAndText';
import TextExercise from './TextExercise';
import ArrangeASentence from './ArrangeASentence';
import MyContext from './MyContext';

class Exercise extends React.Component {

  // get the function we need to render an exercise and execute it
  renderExercise(){
    const { exercise, user, solutions, direct_uploads_url } = this.props;

    const exercises = {
      'Quiz'      :   this.renderQuiz,
      'Flashcard' :   this.renderFlashcard,
      'Copycat Quiz' :   this.renderCopycatQuiz,
      'Blanks Grid' :   this.renderBlanksGrid,
      'Fill in the Blank' :   this.renderFillInTheBlank,
      'Find the Mistake' :   this.renderFindTheMistake,
      'Maze' :   this.renderMaze,
      'Match' :   this.renderMatch,
      'Text Exercise' :   this.renderTextExercise,
      'Arrange A Sentence' :   this.renderArrangeASentence,
    }

    const exerciseProps = {
      lessonId: exercise.lesson_id,
      nextId: exercise.next_id,
      user: user,
      languageName: exercise.language_name,
      solutions: solutions,
      direct_uploads_url: direct_uploads_url
    }

    return exercises[exercise.exercise_type](exercise, exerciseProps)
  }

  renderQuiz(exercise, props){
    return <Quiz
              questions={exercise.questions}
              quiz={exercise.quiz}
              {...props}
            />
  }

  renderFlashcard(exercise, props){
    return <Flashcard
              flashes={exercise.flashes}
              {...props}
           />
  }

  renderCopycatQuiz(exercise, props){
    return <CopycatQuiz
              copycats={exercise.copycats}
              copycat_quiz={exercise.copycat_quiz}
              {...props}
           />
  }

  renderBlanksGrid(exercise, props){
    return <BlanksGrid
              blanksGrid={exercise.blanks_grid}
              {...props}
           />
  }

  renderFillInTheBlank(exercise, props){
    return <FillInTheBlank
              texts={exercise.texts}
              fillInTheBlank={exercise.fill_in_the_blank}
              {...props}
           />
  }

  renderFindTheMistake(exercise, props){
    return <FindTheMistake
              texts={exercise.texts}
              findTheMistake={exercise.find_the_mistake}
              {...props}
           />
  }

  renderMaze(exercise, props){
    if(exercise.maze.correct_indices){
      return <Maze
              maze={exercise.maze}
              {...props}
           />
    } else {
      return <OldMaze
              maze={exercise.maze}
              {...props}
           />
    }
    
  }

  renderTextExercise(exercise, props){
    return <TextExercise
              textExercise={exercise.text_exercise}
              {...props}
           />
  }

  renderArrangeASentence(exercise, props){
    return <ArrangeASentence
              arrangeASentence={exercise.arrange_a_sentence}
              sentences={exercise.sentences}
              {...props}
           />
  }

  renderMatch(exercise, props){
    return <MatchImageAndText
                matchPairs={exercise.match_pairs}
                match={exercise.match}
                {...props}
             />
  }

  render () {
    const { exercise } = this.props;

    const context = {
      images: this.props.images,
      type: exercise.exercise_type
    }

    return (
      <MyContext.Provider  value={context} >
        <div className='text-center xlmb'>
          <p className='bio'>{exercise.bio}</p>

          {this.renderExercise()}
        </div>
      </MyContext.Provider>
    );
  }
}

Exercise.propTypes = {
  exercise: PropTypes.object
};

export default Exercise
