import React from "react";
import PropTypes from "prop-types";
import MyContext from './MyContext';
import Modal from './Modal'

class RevealMatchAnswer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  formattedAnswers() {
    return this.props.answers.join(', ')
  }


  renderAnswers() {
    return this.props.answers.map(answer => (
      <div className="grid-x grid-margin-x align-center-middle mmb">
        <div className="cell small-5">
          {answer.a_text && <p>{answer.a_text}</p>}
          {answer.a_image && <img src={answer.a_image.src} alt={answer.a_image} />}
        </div>
        <div className="cell small-2">
          <img
            src={this.context.images.arrow_right}
            alt=""
          />
        </div>
        <div className="cell small-5">
          {answer.b_text && <p>{answer.b_text}</p>}
          {answer.b_image && <img src={answer.b_image.src} alt={answer.b_image.name} />}
        </div>
      </div>
    ))
  }

  render() {
    if (this.props.buttonVisible) {
      return (
        <div className='mmt'>
          <button
            className='button small reveal-answer-button'
            onClick={() => this.setState({ visible: true })}
          >
            <i class="fa fa-eye inline-block" aria-hidden="true"></i>
            {this.props.buttonText}
          </button>

          {
            this.state.visible &&
            <Modal onModalClose={() => this.setState({ visible: false })}>
              {this.renderAnswers()}
            </Modal>
          }
        </div>
      );
    } else return null;
  }
}

RevealMatchAnswer.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  buttonVisible: PropTypes.bool,
  buttonText: PropTypes.string
};

RevealMatchAnswer.defaultProps = {
  buttonVisible: true,
  buttonText: 'Reveal Answer'
};


RevealMatchAnswer.contextType = MyContext;
export default RevealMatchAnswer;
