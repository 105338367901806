import React from "react";
import PropTypes from "prop-types";
import MyContext from './MyContext'
import OptionButton from './OptionButton';;

class MultipleChoiceQuestion extends React.Component {
  render () {
    const {
      options,
      handleOptionSelect,
      feedback,
      lastQuestion,
      handleNext,
      nextButtonText,
      showNext
    } = this.props;

    const buttons = options.map((o, i) => {
      return <OptionButton handleClick={handleOptionSelect} option={o} key={'option-' + i} id={'option-' + o} />
    })

    return (
      <React.Fragment>
        <section className="grid-container mmb">
          <div className="grid-x grid-margin-x grid-margin-y align-center">
            {buttons}
          </div>
        </section>

        <div
          className={`cell medium-order-2 large-order-3 lf-feedback-flash toggle-trans ${feedback ? '' : 'trans'}`}
        >
          <p className='h4'>{feedback}</p>

        </div>

        {showNext &&
          <button
            className={`lf-submit-button medium-order-4 large-order-4 button lf-next-button cell small-4`}
            onClick={handleNext}
          >
            <span className='show-for-large'>
              {nextButtonText()}
            </span>
            <img
              src={this.context.images.next_black}
              alt="next"
              className='hide-for-large'
            />
          </button>
        }

      </React.Fragment>
    );
  }
}

MultipleChoiceQuestion.propTypes = {
  handleOptionSelect: PropTypes.func,
  options: PropTypes.array,
  feedback: PropTypes.string,
  handleNext: PropTypes.func,
  nextButtonText: PropTypes.func,
  answered: PropTypes.func,
};

MultipleChoiceQuestion.contextType = MyContext;

export default MultipleChoiceQuestion
