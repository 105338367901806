import React from "react";
import PropTypes from "prop-types";

class RevealFindTheMistake extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  formattedAnswers(){
    return this.props.answers.join(', ')
  }

  renderAnswer(){
    return <React.Fragment>
      <p>Here is the text with the corrections highlighted:</p>
      <p className='lead'>
        {this.parsedText()}
      </p>
    </React.Fragment>
  }

  // text and answer do not line up
  parsedText(){
    const elements = [];
    let start = 0;
    const { text } = this.props;

    text.wrong_indices.forEach((indices, i) => {
      // create a <p> for first section if any
      const first = text.text.slice(start, indices[0]);
      if(first.length > 0) elements.push(<span key={'p-' + i}>{first}</span>)
      // update start for next iteration
      start = indices[0] + indices[1];

      const end = text.answer.slice(indices[0], text.answer.length).split(' ')[0].length + indices[0];
      const correctString = text.answer.slice(indices[0], end);
      elements.push(<span className='underline bold'>{correctString} </span>)
    })

    // add last bit of text if there is any
    const lastSection = text.text.slice(start, text.text.length);
    if(lastSection.length > 0) elements.push(<span key={'p-' + elements.length}>{lastSection}</span>)

    return elements;
  }

  render () {
    if(this.props.buttonVisible){
      return (
        <div className='mmt'>
          <button
            className='italic block margin-auto hand-on-hover light'
            onClick={() => this.setState({ visible: true })}
          >
            Reveal Answer
          </button>

          <div
            className={`modal-container toggle-trans grid-x align-center-middle ${this.state.visible ? '' : 'trans'}`}
          >
            <div
              className="white-bg border-radius lp cell small-10 medium-6 large-5 relative"
            >
              <button
                onClick={() => this.setState({ visible:false })}
                className="close-button"
              >
                &times;
              </button>

              {this.renderAnswer()}
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

RevealFindTheMistake.propTypes = {
  text: PropTypes.object.isRequired,
  buttonVisible: PropTypes.bool
};

RevealFindTheMistake.defaultProps = {
  buttonVisible: true
};



export default RevealFindTheMistake;
