import React from "react"
import PropTypes from "prop-types"
import MyContext from './MyContext';

class NextButton extends React.Component {
  render () {
    const {
      visible,
      last,
      disabled,
      onClick,
      text
    } = this.props;

    if(visible) return (
      <button
        disabled={disabled}
        className={`lf-submit-button medium-order-4 large-order-4 button lf-next-button cell small-4 ${last ? 'finished' : ''}`}
        onClick={onClick}
      >
        <span className='show-for-large'>
          {text}
        </span>
        <img
          src={this.context.images.next_black}
          alt="next"
          className={'hide-for-large'}
        />
      </button>
    );
    else return null;
  }
}

NextButton.propTypes = {
  visible: PropTypes.bool,
  last: PropTypes.bool,
  // disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

NextButton.defaultProps = {
  visible: true,
  disabled: false
}

 NextButton.contextType = MyContext;

export default NextButton
