import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat-message"
export default class extends Controller {
  connect() {
    window.addEventListener('load', () => {
      this.element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    });

    this.element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }
}
