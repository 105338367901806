export const specialCharactersList = [
  'á', 'Á',
  'à', 'À',
  'â', 'Â',
  'ä', 'Ä',
  'æ', 'Æ',
  'ç', 'Ç',
  'é', 'É',
  'è', 'È',
  'ê', 'Ê',
  'ë', 'Ë',
  'í', 'Í',
  'ì', 'Ì',
  'î', 'Î',
  'ï', 'Ï',
  '¡',
  'ñ', 'Ñ',
  'ó', 'Ó',
  'ò', 'Ò',
  'ô', 'Ô',
  'ö', 'Ö',
  'œ', 'Œ',
  'ú', 'Ú',
  'ù', 'Ù',
  'û', 'Û',
  'ü', 'Ü',
  '¿', 
  'ß'
];