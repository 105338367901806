const makeFirstTabActive = () => {
  const firstTabTitle = document.querySelectorAll('.dashboard .tabs-title')
  const firstTabPanel = document.querySelectorAll('.dashboard .tabs-panel')

  if (firstTabTitle.length !== 0 && firstTabPanel.length!== 0) {
    firstTabTitle[0].classList.add('is-active')
    firstTabPanel[0].classList.add('is-active')
  }
}

document.addEventListener('turbo:load', makeFirstTabActive)