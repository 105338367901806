import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="message"

const COUNTER_LEVELS = {
  150: "tw-text-gray-500",
  180: "tw-text-yellow-500",
  200: "tw-text-red-500"
}

export default class extends Controller {
  static targets = [ "messages", "form", "content", "counter" ]

  connect() {
    this.scrollToBottom()
  }

  scrollToBottom() {
    this.element.scrollTop = this.element.scrollHeight
  }

  resetForm() {
    this.element.reset()
    this.scrollToBottom() 
  }

  countCharacters() {
    const count = this.contentTarget.value.length
    this.counterTarget.innerText = `${count}/200`
    this.counterTarget.classList = COUNTER_LEVELS[this.countToLevel(count)]
  }

  countToLevel(count) {
    const levels = Object.keys(COUNTER_LEVELS).map(Number)
    return levels.find(level => count <= level)
  }

  submit(event) {
    if (event.keyCode === 13 && event.shiftKey) {
      return
    }

    event.preventDefault()

    this.element.requestSubmit();
    this.resetForm();
  }
}
